import { Redirect, Route, Switch } from "react-router-dom"

import DepositReferrals from "../pages/Deposit_Referrals"
import DepositStake from '../pages/Deposit'
import React from "react"
import ReferralPage from "../pages/Referral"
import Stake from '../pages/Stake'
import Swap from '../pages/Swap'
import UpgradeSPNDPage from "../pages/Upgrade"
import Wallet from '../pages/Wallet'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Wallet} />
      <Route exact path="/swap" component={Swap} />
      <Route exact path="/stake" component={Stake} />
      <Route exact path="/deposit-stake" component={DepositStake} />
      <Route exact path="/upgrade" component={UpgradeSPNDPage} />
      <Route exact path="/referral" component={ReferralPage} />
      <Route exact path="/deposit-referrals" component={DepositReferrals} />
      <Route render={() => <Redirect to="/"/>}/>
    </Switch>
  )
}
