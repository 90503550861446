import './App.css'

import { useCloseTxToast, useTx, useTxToast } from './state/tx/hooks'

import ApplicationUpdater from './state/application/updater'
import { FaRegCheckCircle } from 'react-icons/fa'
import { Footer } from './containers/Footer'
import Header from './containers/Header'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import Routes from './containers/routes'
import SelectTokenModal from './containers/SelectTokenModal'
import { Snackbar } from '@material-ui/core'
import SwapUpdater from './state/swap/updater'
import MulticallUpdater from './state/multicall/updater'
import WalletModal from './containers/WalletModal'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import WrongNetworkModal from './containers/WrongNetworkModal'
import configureStore from './state'
import styled from 'styled-components'
import WalletTokenModal from './containers/WalletTokenModal'
import './hooks/useLocalstorage'
import RemoveTokenModal from './containers/RemoveTokenModal'

function getLibrary(provider) {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

const reduxStore = configureStore()

function Updaters() {
  return (
      <>
          <ApplicationUpdater />
          <SwapUpdater />
          <MulticallUpdater />
      </>
  )
}

function Modals() {
  const tx = useTx()
  const toast = useTxToast()
  const closeTxToast = useCloseTxToast()
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    closeTxToast()
  }
  return <>
    <WalletModal />
    <WrongNetworkModal />
    <SelectTokenModal />
    <WalletTokenModal />
    <RemoveTokenModal />
    <Snackbar open={toast} autoHideDuration={5000} onClose={handleClose}>
      <div
        className={`
          w-full max-w-3xl p-3 rounded-md
          ${tx.txStatus ? 'cursor-pointer' : ''}
        `}
        style={{
          background: tx.txStatus ? "rgba(61, 199, 99, 0.3)" : "rgba(237, 61, 61, 0.3)",
          backdropFilter: "blur(5px)"
        }}
        onClick={() => {
          if(!tx.txStatus) {
            handleClose()
          } else {
            window.open('https://bscscan.com/tx/' + tx.txId)
          }
        }}
      >
        <div className="w-full flex flex-row items-center">
          <FaRegCheckCircle color={tx.txStatus ? "rgb(61, 199, 99)" : "rgb(237, 61, 61)"} size={32} className="mr-3" />
          <div className="text-white flex flex-col items-center text-left">
            <span className="w-full">Transaction #{tx.txIndex} {tx.txStatus ? 'Completed' : 'Failed'}!</span>
            <span className="w-full opacity-60" style={{fontSize: 11}}>{tx.txStatus ? 'Tap to view this transaction' : tx.txDescription}</span>
          </div>
        </div>
      </div>
    </Snackbar>
  </>
}

const PurpleRadial = styled.div`
    background: radial-gradient(38.72% 38.72% at 50% 50%, var(--platform-secondary) 0%, rgba(78, 85, 94, 0.37) 100%);
    margin-left: calc((-588px / 2) + 91px);
    margin-top: calc((-576px / 2) + 79px);
`

const GreenRadial = styled.div`
    background: radial-gradient(38.72% 38.72% at 50% 50%, var(--platform-primary) 0%, rgba(78, 85, 94, 0.37) 100%);
    margin-left: calc((-588px / 2) + -43px);
    margin-top: calc((-576px / 2) + -14px);
`

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ReduxProvider store={reduxStore}>
        <Updaters />
        <div className="App flex flex-col">
          <PurpleRadial className="Radials" />
          <GreenRadial className="Radials" />
          <div className="w-full relative sticky top-0 z-10">
            <Header />
          </div>
          <div
            className="DisplayBox w-full flex-grow relative flex flex-col mx-auto items-center justify-center px-5 py-3"
          >
            <Routes />
          </div>
          <Footer className="sticky bottom-0 z-10" />
          <Modals />
        </div>
      </ReduxProvider>
    </Web3ReactProvider>
  )
}

export default App
