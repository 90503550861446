import React, { useEffect, useRef, useState } from 'react'
import { TokenKeys, resetToken, setCurrentToken, setToken } from '../../state/swap/actions'
import { formatValue, toFloat } from '../../utils/number'
import { getPrice, isRightNetwork } from '../../utils/web3'
import { useHistory, useLocation } from 'react-router-dom';

import Button from '../../components/Button'
import CopyIcon from '../../assets/copy.svg'
import { FaInfoCircle } from 'react-icons/fa';
import { ROUTERS } from '../../constants/contracts';
import { Snackbar } from '@material-ui/core'
import TokenSelect from '../../components/TokenSelect'
import { Tokens } from '../../constants/tokens';
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components';
import { useContract } from '../../hooks/useContract';
import { useDispatch } from 'react-redux'
import { useGetToken } from '../../state/swap/hooks';
import { useWalletModalToggle } from '../../state/application/hooks'
import { useWeb3React } from '@web3-react/core'

const ReferralContainer = styled.div`
background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
background-blend-mode: soft-light, normal;
border-radius: 15px;
position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 .75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ReferralLink = styled.div`
  background: #3a3f46;
  cursor: pointer;
  color: white;
  padding: 0.5rem;
  width: 100%;
  margin: 10px 10px 10px 0;
  border-radius: 0.5rem;
  resize: none;
  align-tems: flex-start;
  text-align: start;
  word-break: break-all;
  width: 100%;


  &:focus {
    outline: none;
  }
`

export default function ReferralPage() {
  const { chainId, account, library } = useWeb3React();
  const token = useGetToken(TokenKeys.TOKEN_G);
  const referUrl = useRef();
  const STATUS = {
    READY: 0,
    PENDING: 1
  }
  const [status, setStatus] = useState(STATUS.READY)
  const [copied, setCopied] = useState(false)
  const [referralInformation, setReferralInformation] = useState({
    total_bnb: 0,
    total_usd: 0,
    symbol: '',
    account: ''
  })

  let tokenCls = undefined;
  if(token) {
    tokenCls = Tokens.customToToken(token);
  }

  const dispatch = useDispatch();
  const history = useHistory()
  const search = useLocation().search;
  const toggleWalletModal = useWalletModalToggle()

  const anjiReferral = useContract(ROUTERS.anji_referral.address, ROUTERS.anji_referral.abi, true);
  const  claimReward = async () => {
    try {
      let tx = null
      if(referralInformation.total_bnb > 0) {
        setStatus(STATUS.PENDING)
        tx = await anjiReferral.claimReward(account, token.address);
      }
      else {
        dispatch(setTx(``, `No BNB`,  false))
      }
      if(tx) {
        tx = await tx.wait(1)
        dispatch(setTx(tx.transactionHash, `Claimed`, true))
        setReferralInformation({
          total_bnb: 0,
          total_usd: 0,
          symbol: '',
          account: '',
          enough_to_pay: false
        });
        dispatch(resetToken(TokenKeys.TOKEN_G));
      }
    } catch(e) {
      setReferralInformation({
        total_bnb: 0,
        total_usd: 0,
        symbol: '',
        account: '',
        enough_to_pay: false
      });
      dispatch(resetToken(TokenKeys.TOKEN_G));
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  useEffect( () => {
    getReferralInfo()
  }, [dispatch, search, chainId, account])

  useEffect(() => {
    if (tokenCls) {
      const referralParam = new URLSearchParams(search).get('token') || ''
      if(referralParam.toLowerCase() !== tokenCls.symbol.toLowerCase()) {
          history.replace({ search: new URLSearchParams({ token: tokenCls.symbol }).toString() })
      }
    }
  }, [tokenCls])

  async function getReferralInfo () {
    const referralParam = new URLSearchParams(search).get('token') || 'ANJI';
    const token = Tokens.getToken(referralParam.toUpperCase())
    const bnbToken = Tokens.bnb;
    if  (token && isRightNetwork(chainId) && bnbToken)  {
      let tb = parseFloat((await anjiReferral.debtPerReferrerAndToken(account, token.address)).toString())/(10 ** bnbToken.decimals);
      const fullPrice = bnbToken.calculatePrice({ [bnbToken.symbol]: await getPrice(bnbToken.pair_address, bnbToken.decimals, library, bnbToken.symbol) }, { [bnbToken.symbol]: tb });

      const usd = `$${formatValue((Math.trunc(toFloat(fullPrice) * 100) / 100).toFixed(2))}`

      const tokenDeposited = toFloat((await anjiReferral.depositedPerToken(token.address)));

      setReferralInformation({
        total_bnb: tb,
        total_usd: usd,
        symbol: token.symbol,
        account: account,
        enough_to_pay: tokenDeposited >= tb
      })
      dispatch(setCurrentToken(TokenKeys.TOKEN_G))
      dispatch(setToken(token))
    } else {
      setReferralInformation({
        total_bnb: 0,
        total_usd: 0,
        symbol: '',
        account: '',
        enough_to_pay: false
      });
    }
  }

  function copyToClipboard (str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <ReferralContainer className="w-full max-w-2xl dark-box-shadow">
      <div
        className="px-3 pt-3 text-white flex items-center justify-between"
      >
        <span>Referral Rewards</span>
      </div>
      <Divider />
      <TokenArea>
        <TokenSelect tokenKey={TokenKeys.TOKEN_G} title="Referral"/>
          <div
            className="px-3 pt-3 text-white flex items-center justify-left"
          >
          </div>
      </TokenArea>
      <div
        className="px-3 pt-3 text-white flex items-center justify-between"
      >
        <span>Referral Information</span>
      </div>
        <div className="flex justify-between px-3 w-full text-white text-sm number-font" style={{fontSize: 12}}>
            <span>Total BNB</span>
            <span>{ (referralInformation.total_bnb) }</span>
        </div>
        <div className="flex w-full justify-between px-3 text-white text-sm number-font" style={{fontSize: 12}}>
            <span>USD</span>
            <span>{ referralInformation.total_usd }</span>
        </div>
        {
            account && isRightNetwork(chainId) ?
                <div className="flex justify-between px-3 text-white" style={{fontSize: 12}}>
                    <ReferralLink className="select-all">
                        {'https://app.anji.eco/swap?token=' + referralInformation.symbol + '&referrer=' + referralInformation.account}
                    </ReferralLink>
                    <Button
                        onClick = {() => {
                        const str = `https://app.anji.eco/swap?token=${referralInformation.symbol}&referrer=${referralInformation.account}`;

                        copyToClipboard(str)
                        setCopied(true);
                        }}>
                        <img style={{ width: '20px' }} src={CopyIcon} alt = 'copy_icon'/>
                    </Button>
                </div>
                :
                null
        }
      <div className="flex items-center opacity-60 p-3 text-left text-white text-xs">
        <FaInfoCircle className="mr-1" style={{ fontSize: 16 }} /> Please be aware that you can not use your own referral link when buying and will be processed as a normal transaction.
     </div>
      <div
          className="p-3 flex items-center justify-between flex-col"
      >
        {
          account && isRightNetwork(chainId) ?
            <Button
              className="w-full anji-colorful"
              disabled={status === STATUS.PENDING || referralInformation.total_bnb * 100 / 100 === 0 || !referralInformation.enough_to_pay }
              onClick={claimReward}
            >
              Claim Referral Rewards
            </Button>
          :
            <Button
              className="w-full anji-green"
              disabled={account && isRightNetwork(chainId)}
              onClick={toggleWalletModal}
            >
              {
                (!account || !isRightNetwork(chainId)) ? 'Connect Wallet':''
              }
            </Button>
          }
      </div>
      <Snackbar open={copied} autoHideDuration={500000} onClose={ () => setCopied(false) }>
        <div
            className={'w-full max-w-3xl p-3 rounded-md'}
            style={{
                background: "rgba(61, 199, 99, 0.3)",
                backdropFilter: "blur(5px)"
            }}
            onClick={() => {
                setCopied(false)
             }}>
            <div className="w-full flex flex-row items-center">
                <img style={{ width: '20px' }} src={CopyIcon} alt='copy_icon' className="mr-3" />
                <div className="text-white flex flex-col items-center text-left">
                    Copied
                </div>
            </div>
        </div>
      </Snackbar>
    </ReferralContainer>
  )
}
