import { Token } from '@pancakeswap/sdk'
import { parseBytes32String } from '@ethersproject/strings'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { useSelectedTokenList } from '../state/lists/hooks'
import { useUserAddedTokens } from '../state/user/hooks'
import { isAddress } from '../utils'
import { useBytes32TokenContract, useTokenContract } from './useContract'
import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
// eslint-disable-next-line import/no-cycle

export function useAllTokens() {
    const { chainId } = useWeb3React()
    const userAddedTokens = useUserAddedTokens()
    const allTokens = useSelectedTokenList()

    return useMemo(() => {
        if (!chainId) return {}
        return (
            userAddedTokens
                // reduce into all ALL_TOKENS filtered by the current chain
                .reduce(
                    (tokenMap, token) => {
                        tokenMap[token.address] = token
                        return tokenMap
                    },
                    // must make a copy because reduce modifies the map, and we do not
                    // want to make a copy in every iteration
                    { ...allTokens[chainId] }
                )
        )
    }, [chainId, userAddedTokens, allTokens])
}

const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/
function parseStringOrBytes32(str, bytes32, defaultValue) {
    return str && str.length > 0
        ? str
        : bytes32 && BYTES32_REGEX.test(bytes32)
            ? parseBytes32String(bytes32)
            : defaultValue
}

export function useToken(tokenAddress) {
    const { chainId } = useWeb3React()
    const tokens = useAllTokens()
    const address = isAddress(tokenAddress)

    const tokenContract = useTokenContract(address || undefined, false)
    const tokenContractBytes32 = useBytes32TokenContract(address || undefined, false)
    const token = address ? tokens[address] : undefined

    const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD)
    const tokenNameBytes32 = useSingleCallResult(
        token ? undefined : tokenContractBytes32,
        'name',
        undefined,
        NEVER_RELOAD
    )
    const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD)
    const symbolBytes32 = useSingleCallResult(token ? undefined : tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
    const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD)

    return useMemo(() => {
        if (token) return token
        if (!chainId || !address) return undefined
        if (decimals.loading || symbol.loading || tokenName.loading) return null
        if (decimals.result) {
            return new Token(
                chainId,
                address,
                decimals.result[0],
                parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
                parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token')
            )
        }
        return undefined
    }, [
        address,
        chainId,
        decimals.loading,
        decimals.result,
        symbol.loading,
        symbol.result,
        symbolBytes32.result,
        token,
        tokenName.loading,
        tokenName.result,
        tokenNameBytes32.result,
    ])
}