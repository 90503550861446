import { BsArrowLeft, BsExclamationCircle } from 'react-icons/bs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TokenKeys, resetToken, setCurrentToken, setToken } from '../../state/swap/actions'
import { formatBN, formatBalance, getBalance, getContract, getEarned, getIsTimeLocked, getShare, getStakable, getStakeAllowance, getStaked, getTimeLockDate, getTimeLockUntil, isRightNetwork } from '../../utils/web3'
import { formatValue, noExponents, toFloat } from '../../utils/number'
import { getStakingContractDetails, useStakingContract } from '../../hooks/useContract'
import { useBlockNumber, useWalletModalToggle } from '../../state/application/hooks'
import { useHistory, useLocation } from "react-router-dom";

import BEP20_ABI from '../../assets/contracts/bep20_abi.json'
import Button from '../../components/Button'
import { IoLockClosedSharp } from 'react-icons/io5'
import { MdLockOpen } from 'react-icons/md'
import Modal from '../../components/Modal'
import Moment from 'react-moment';
import TokenSelect from '../../components/TokenSelect'
import { Tokens } from '../../constants/tokens'
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useGetToken } from '../../state/swap/hooks'
import { useWeb3React } from '@web3-react/core'

const StakeContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TokenAmount = styled.input`
  background: transparent;
  color: white;
  text-align: right;
  width: 200px;
  font-family: 'Roboto Mono', monospace;
  &:focus {
    outline: none;
  }
`
const TokenMax = styled.div`
  font-size: 11px;
  color: white;
  text-align: left;
  padding: 1rem 1rem 0 1rem;
`
const AddMax = styled.div`
  color: var(--platform-primary-bright);
  cursor: pointer;
`

export default function StakePage() {
  const { chainId, account, library } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const dispatch = useDispatch()

  const tokenStake = useGetToken(TokenKeys.TOKEN_C)
  const tokenReward = useGetToken(TokenKeys.TOKEN_D)
  const Staking_Contract = useStakingContract(tokenStake, tokenReward)
  const search = useLocation().search
  const history = useHistory()
  const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)

  const STATUS = {
    READY: 0,
    PENDING: 1
  }
  const [status, setStatus] = useState(STATUS.READY)

  const blockNumber = useBlockNumber()
  const [balanceStake, setStakeBalance] = useState(0)
  const [balanceReward, setRewardBalance] = useState(0)
  const [staked, setStakedStake] = useState(0)
  const [crossStaked, setCrossStaked] = useState(0)
  const [crossStakedLeft, setCrossStakedLeft] = useState(0)
  const [stakable, setStakable] = useState(0)
  const [newStake, setNewStake] = useState('')
  const [earned, setEarnedReward] = useState(0)
  const [allowance, setStakeAllowance] = useState(0)
  const [stakeStep, setStakeStep] = useState(1)
  const [sharePercent, setSharePercent] = useState(0)
  const [stakingRequirementsModal, setStakingRequirementsModal] = useState(0)
  const [unstakeRewardLoss, unstakeRewardLossModal] = useState(0)
  const [isTimeLocked, setIsTimeLocked] = useState(false)
  const [timeLockUntil, setTimeLockUntil] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [timeLockDate, setTimeLockDate] = useState(0)

  const [fetchIndex, setFetchIndex] = useState(0)
  const tryFetch = () => {
    setFetchIndex(fetchIndex + 1)
  }

  useEffect(() => {
    if (tokenStake) {
        const stakeParam = new URLSearchParams(search).get('token') || ''

        if (stakeParam.toLowerCase() !== tokenStake.symbol.toLowerCase()) {
            history.replace({ search: new URLSearchParams({ token: tokenStake.symbol }).toString() })
        }
    }

    async function fetchTokenBalance(tokenAddress, tokenDecimals, setTokenBalance) {
      try {
        let newBalance = await getBalance(account, tokenAddress, library)
        newBalance = formatBalance(newBalance, tokenDecimals)
        setTokenBalance(toFloat(newBalance))
      } catch(e) { console.log(e) }
    }
    async function fetchTokenAllowance() {
      try {
        let newAllowance = await getStakeAllowance(account, tokenStake, tokenReward, library)
        newAllowance = formatBalance(newAllowance, tokenReward.decimals)
        setStakeAllowance(toFloat(newAllowance))
      } catch(e) { console.log(e) }
    }
    async function fetchTokenStaked() {
      try {
        let newStaked = await getStaked(account, tokenStake, tokenReward, library)
        newStaked = formatBalance(newStaked, tokenStake.decimals)
        setStakedStake(toFloat(newStaked))
      } catch(e) { console.log(e) }
    }
    async function fetchMaxStakable() {
      try {
        let newStakable = await getStakable(account, tokenStake, tokenReward, library)
        newStakable = formatBalance(newStakable, tokenStake.decimals)
        setStakable(toFloat(newStakable))
      } catch(e) { console.log(e) }
    }
    async function fetchTokenEarned() {
      try {
        let newEarned = await getEarned(account, tokenStake, tokenReward, library)
        newEarned = formatBalance(newEarned, tokenReward.decimals)
        setEarnedReward(noExponents(toFloat(newEarned)))
      } catch(e) { console.log(e) }
    }
    async function fetchStakeShare() {
      try {
        let newShare = await getShare(account, tokenStake, tokenReward, library)
        newShare = formatBalance(newShare, 2, 2)
        setSharePercent(toFloat(newShare))
      } catch(e) { console.log(e) }
    }
    async function fetchIsTimeLock() {
      try {
        let isTimeLocked = await getIsTimeLocked(account, tokenStake, tokenReward, library)
        setIsTimeLocked(isTimeLocked)
      } catch (e) { console.log(e) }
    }
    async function fetchTimeLockDate() {
      try {
        let timeLockedDate = await getTimeLockDate(account, tokenStake, tokenReward, library)
        setTimeLockDate(timeLockedDate.toString())
      } catch (e) { console.log(e) }
    }
    async function fetchTimeLockUntil() {
      try {
        let timeLockUntil = await getTimeLockUntil(account, tokenStake, tokenReward, library)
        setTimeLockUntil(timeLockUntil.toString())
      } catch (e) { console.log(e) }
    }
    async function fetchCrossStaked() {
      try {
        const stakes = Object.keys(tokenStake.stake_contracts).map(rewardKey => {
            return getStaked(account, tokenStake, Tokens.getToken(rewardKey), library);
          }),
          totals = await Promise.all(stakes),
          total = totals.map(_total => toFloat(formatBalance(_total, tokenStake.decimals))).reduce((a, b) => a + b, 0);

        setCrossStaked(total)
      } catch(e) { console.log(e) }
    }
    async function fetchCrossStakedLeft() {
      try {
        const stakes = Object.keys(tokenStake.stake_contracts).map(rewardKey => {
            return getStaked(account, tokenStake, Tokens.getToken(rewardKey), library);
          }),
          totals = await Promise.all(stakes),
          totalStaked = totals.map(_total => toFloat(formatBalance(_total, tokenStake.decimals))).reduce((a, b) => a + b, 0);

        let unstaked = await getBalance(account, tokenStake.address, library)
        unstaked = toFloat(formatBalance(unstaked, tokenStake.decimals))

        setCrossStakedLeft((unstaked - totalStaked) / 2);
      } catch(e) { console.log(e) }
    }

    if(!library || !account || !isRightNetwork(chainId) || !tokenStake || !tokenReward) {
      setStakeBalance(0)
      setRewardBalance(0)
      setStakeAllowance(0)
      setStakedStake(0)
      setStakable(0)
      setCrossStaked(0)
      setEarnedReward(0)
      setNewStake('')
      setTimeLockUntil(0)
      setTimeLockDate(0)
      setIsTimeLocked(false)
      setCrossStakedLeft(0)
      return
    }

    fetchTokenBalance(tokenStake.address, tokenStake.decimals, setStakeBalance)
    fetchTokenBalance(tokenReward.address, tokenReward.decimals, setRewardBalance)
    fetchTokenAllowance()
    fetchTokenStaked()
    fetchMaxStakable()
    fetchTokenEarned()
    fetchStakeShare()
    fetchTimeLockUntil()
    fetchIsTimeLock()
    fetchTimeLockDate()
    fetchCrossStaked()
    fetchCrossStakedLeft()

  }, [chainId, account, library, blockNumber, tokenStake, tokenReward, fetchIndex, history, search])

  useEffect(() => {
    const stakeParam = new URLSearchParams(search).get('token') || ''
    const loadStakeToken = Tokens.getToken(stakeParam.toUpperCase())

    if (loadStakeToken && loadStakeToken.stake) {
        dispatch(setCurrentToken(TokenKeys.TOKEN_C))
        dispatch(setToken(loadStakeToken))
    } else {
        dispatch(resetToken(TokenKeys.TOKEN_C))
        dispatch(resetToken(TokenKeys.TOKEN_D))
    }
    setTimeout(() => {
      tryFetch()
    }, 100)
// eslint-disable-next-line
  }, [dispatch])

  const approveTokenRequirements = () => {
    setStakingRequirementsModal(true)
  }

  const approveToken = async () => {
    setStakingRequirementsModal(false)
    try {
      if(!library || !account || !isRightNetwork(chainId) || !tokenStake) return
      const tokenContract = getContract(tokenStake.address, BEP20_ABI, library, account)
      const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)
      if(!tokenContract) return
      if(!stakeContractDetails.address) return
      setStatus(STATUS.PENDING)
      let tx = await tokenContract.approve(stakeContractDetails.address, "1000000000000000000000000")
      tx = await tx.wait(1)
      dispatch(setTx(tx.transactionHash, `${tokenStake.symbol} Approved`, true))
      tryFetch()
    } catch(e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  const inputRegex = new RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
  const checkAndUpdate = (amount, callback) => {
    const nextUserInput = amount ? amount.toString().replace(/[,]/g, '') : ''
    if (!nextUserInput || inputRegex.test(nextUserInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))) {
      if(callback)
        callback(nextUserInput.toString())
    }
  }

  const calculatePercent = useCallback(() => {
    let percent = 0
    try {
      const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)
      percent = (staked + toFloat(newStake)) * stakeContractDetails.amount / stakable
    } catch(e) { console.log(e) }
    if(!isFinite(percent))
      percent = 0
    return percent
  }, [staked, newStake, stakable, tokenStake, tokenReward])

  const percentComponent = useMemo(() => {
    const percent = calculatePercent()
    const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)
    if(percent > stakeContractDetails.amount) {
      return <span style={{color: '#FF4B33'}}>~More Than {stakeContractDetails.amount}%</span>
    }
    return <span>~{percent.toFixed(2)}%</span>
  }, [calculatePercent, tokenStake, tokenReward])
  const shareComponent = () => {
    if(sharePercent < 0.01)
      return '<0.01'
    return sharePercent.toFixed(2)
  }

  const stake = async () => {
    try {
      if(!Staking_Contract) return
      if(toFloat(newStake) === 0) return
      if(calculatePercent() > stakeContractDetails.amount) return
      if (stakeContractDetails.deprecated) return
      setStatus(STATUS.PENDING)
      let tx
      if(tokenStake.name === Tokens.getToken('SPND').name)
        tx = await Staking_Contract.stakeSafePanda(formatBN(newStake, tokenReward.decimals))
      else
        tx = await Staking_Contract.stake(formatBN(newStake, tokenReward.decimals))
      tx = await tx.wait(1)
      dispatch(setTx(tx.transactionHash, `${toFloat(newStake)} ${tokenStake.symbol} Staked`, true))
      setNewStake('')
      setStakeStep(1)
      tryFetch()
    } catch(e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  const unstakeCheck = () => {
    if (calculatePercent() > stakeContractDetails.amount) {
      unstakeRewardLossModal(true)
    } else {
      unstake()
    }
  }

  const cancelUnstake = () => {
    unstakeRewardLossModal(false)
  }

  const unstake = async () => {
    try {
      unstakeRewardLossModal(false)
      if(!Staking_Contract) return
      if(staked === 0) return
      setStatus(STATUS.PENDING)
      let tx
      if(tokenStake.name === Tokens.getToken('SPND').name)
        tx = await Staking_Contract._unstakeAll()
      else
        tx = await Staking_Contract.unstakeAll()
      tx = await tx.wait(1)
      dispatch(setTx(tx.transactionHash, `${tokenStake.name} Unstaked`, true))
      tryFetch()
    } catch(e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }
  const harvest = async () => {
    try {
      if(!Staking_Contract) return
      if(earned === 0) return
      setStatus(STATUS.PENDING)
      let tx
      if(tokenStake.name === Tokens.getToken('SPND').name)
        tx = await Staking_Contract._claimAllBamboo()
      else
        tx = await Staking_Contract.harvest()
      tx = await tx.wait(1)
      dispatch(setTx(tx.transactionHash, `${tokenReward.symbol} Harvested`, true))
      tryFetch()
    } catch(e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  return (
    <>
      <StakeContainer
        className="w-full max-w-2xl dark-box-shadow"
      >
        <div
          className="px-3 pt-3 text-white flex items-center justify-between"
        >
          <span>Stake</span>
          {
            account && isRightNetwork(chainId) ?
              <MdLockOpen width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
            :
              <IoLockClosedSharp width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
          }
        </div>
        <Divider />
        <TokenArea
        >
          <TokenSelect tokenKey={TokenKeys.TOKEN_C} title="Stake" />
          <TokenAmount
            placeholder="0.0"
            disabled={ !account || !isRightNetwork(chainId) || !tokenStake || !tokenReward }
            onChange={ (e) => checkAndUpdate(e.target.value, setNewStake) }
            value={ staked > 0 && stakeStep === 1 ?  formatValue(staked) : formatValue(newStake) }
            readOnly={staked > 0 && stakeStep === 1}
          />
        </TokenArea>
        <TokenMax>
          <div className="flex flex-row justify-between number-font opacity-60">
            <span>{formatValue(balanceStake, '0.0')} {tokenStake && tokenStake.symbol}</span>
            {percentComponent}
          </div>
          {
            (account && isRightNetwork(chainId)) && tokenReward && tokenStake && Object.keys(tokenStake.stake_contracts).length > 1 ?
            (
              <div className="flex flex-row justify-between">
                <span className="opacity-60">Total { tokenStake.symbol } Cross Stake Contracts</span>
                <span className="number-font">
                  { formatValue(crossStaked, '0.0') }
                </span>
              </div>
            )
            :
            null
          }
          {
            (account && isRightNetwork(chainId)) && tokenReward && tokenStake ?
              (
                ((stakeContractDetails.amount === 100 && staked > 0) || crossStakedLeft > 0) ?
                  <AddMax
                    onClick={() => {
                        if (stakeContractDetails.deprecated) return
                        if(staked > 0 && stakeStep === 1) {
                            setStakeStep(2)
                        } else if (stakeContractDetails.amount === 100) {
                            checkAndUpdate(Math.trunc(toFloat(stakable) - toFloat(staked)), setNewStake)
                        } else {
                            checkAndUpdate(Math.trunc(toFloat(crossStakedLeft)), setNewStake)
                        }
                    }}
                    className={ (stakeContractDetails.deprecated) ? 'opacity-40' : '' }
                  >
                    {
                      staked > 0 && stakeStep === 1 ? `Add To Stake (Max ${stakeContractDetails.amount}%)` : `Stake MAX (${stakeContractDetails.amount}%)`
                    }
                    {stakeContractDetails.deprecated}
                  </AddMax>
                :
                  <div className="number-font opacity-60">
                    Max Staked ({stakeContractDetails.amount}%)
                  </div>
              )
            :
              null
          }
        </TokenMax>
        <div
          className="p-3 flex items-center justify-between flex-col"
        >
          {
            account && isRightNetwork(chainId) ?
              stakeContractDetails.time_lock ?
                isTimeLocked ?
                  staked === 0 || stakeStep === 1 ?
                    <div className="flex flex-row justify-between number-font opacity-60 mb-2" style={{fontSize: 13, opacity: 0.6, color: 'white'}}>
                      Locked until&nbsp;<Moment unix format="DD MMM YYYY HH:mm (UTC)">{ timeLockUntil }</Moment>
                  </div>
                  :
                  <div className="flex flex-row justify-between number-font opacity-60 mb-2" style={{fontSize: 13, opacity: 0.6, color: 'white'}}>
                    Will be locked until&nbsp;<Moment add={{ days: stakeContractDetails.time_lock }} format="DD MMM YYYY HH:mm (UTC)">{ new Date().getTime() }</Moment>
                  </div>
                :
                staked === 0 || stakeStep === 2 ?
                  <div className="flex flex-row justify-between number-font opacity-60 mb-2" style={{fontSize: 13, opacity: 0.6, color: 'white'}}>
                    Will be locked until&nbsp;<Moment add={{ days: stakeContractDetails.time_lock }} format="DD MMM YYYY HH:mm (UTC)">{ new Date().getTime() }</Moment>
                  </div>
                :
                null
              :
              null
            :
            null
          }
          {
              stakeContractDetails?.deprecated ?
                <div className="w-full">
                    <p className="text-xs text-white opacity-60 mb-2">
                        Staking {tokenStake.symbol} for {tokenReward.symbol} is currently on hold.<br />
                        {
                            staked > 0 ?
                                `Please unstake your ${tokenStake.symbol} and then claim your rewards`
                                :
                                null
                        }
                    </p>
                </div>
                :
                null
          }
          {
            account && isRightNetwork(chainId) ?
              staked === 0 ?
                <Button
                    className="w-full anji-colorful"
                    disabled={stakeContractDetails.deprecated ||  status === STATUS.PENDING ||  toFloat(newStake) <= 0 ||  calculatePercent() > stakeContractDetails.amount ||  allowance < toFloat(newStake) ||  (stakeContractDetails.amount !== 100 && crossStakedLeft < toFloat(newStake)) }
                    onClick={stake}
                >
                  Stake
                </Button>
              : stakeStep === 1 ?
                <Button
                  className="w-full anji-green"
                  disabled={status === STATUS.PENDING || (stakeContractDetails.time_lock > 0 && isTimeLocked)}
                  onClick={unstakeCheck}
                >
                  Unstake
                </Button>
              :
                <div className="w-full">
                  <Button
                    className="w-full anji-colorful"
                    disabled={stakeContractDetails.deprecated || status === STATUS.PENDING || calculatePercent() > stakeContractDetails.amount || allowance < toFloat(newStake) || (stakeContractDetails.amount !== 100 && crossStakedLeft < toFloat(newStake))}
                    onClick={stake}
                  >
                    Add To Stake
                  </Button>
                  <div style={{fontSize: 11, opacity: 0.6}} className="mt-3 text-white flex flex-row items-center justify-center cursor-pointer" onClick={() => {
                    setNewStake(0)
                    setStakeStep(1)
                  }}>
                    <BsArrowLeft className="mr-1" size={16} />
                    Back to Unstake
                  </div>
                </div>
            :
              <Button
                className="w-full anji-green"
                disabled={status === STATUS.PENDING}
                onClick={!account ? toggleWalletModal : null}
              >
                Connect Wallet
              </Button>
            }
        </div>
        <Divider />
        <TokenArea
        >
          <TokenSelect tokenKey={TokenKeys.TOKEN_D} title="Reward" />
          <TokenAmount
            placeholder="0.0"
            readOnly
            disabled={ !account || !isRightNetwork(chainId) || !tokenStake || !tokenReward }
            value={ formatValue(earned) }
          />
        </TokenArea>
        <TokenMax>
          <div className="flex flex-row items-center justify-between">
            <span className="number-font opacity-60">{formatValue(balanceReward, '0.0')} {tokenReward && tokenReward.symbol}</span>
            <span className="number-font">Amount Earned</span>
          </div>
        </TokenMax>
        <div
          className="p-3 flex items-center justify-between flex-col"
        >
          {
            account && isRightNetwork(chainId) && earned > 0 ?
                <>
                    {
                        stakeContractDetails?.deprecated && staked > 0 ?
                            <div className="w-full">
                                <p className="text-xs text-white opacity-60 mb-2">
                                    Please unstake unstake before claiming your rewards.
                                </p>
                            </div>
                        :
                        null
                    }
                    <Button
                        className="w-full anji-colorful"
                        disabled={status === STATUS.PENDING || calculatePercent() > stakeContractDetails.amount || isTimeLocked || (stakeContractDetails.deprecated && staked > 0)}
                        onClick={harvest}
                    >
                        Harvest
                    </Button>
                </>
            :
              null
            }
        </div>
        {
          account && isRightNetwork(chainId) && staked > 0 ?
            <>
              <Divider />
              <div style={{fontSize: 11}} className="text-white pt-3 pb-5 px-3 flex flex-row items-center justify-between number-font">
                <span>Percentage Of Pool = {shareComponent()}%</span>
                <BsExclamationCircle size={16} />
              </div>
            </>
          : null
        }
      </StakeContainer>
      {
        account && isRightNetwork(chainId) && allowance < newStake ?
          <div className="mt-5 text-white w-full">
            <div style={{ fontSize: 11 }}>
              Approve your wallet to allow AnjiSwap to stake.
            </div>
            <div
              className="anji-green rounded-3xl py-3 mt-3 cursor-pointer btn"
              onClick={status === STATUS.READY ? approveTokenRequirements : null}
            >
              Approve
              {
                status === STATUS.PENDING &&
                  <div
                    className="absolute w-full h-full left-0 top-0 rounded-full"
                    style={{
                      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
                    }}
                  />
              }
            </div>
          </div>
        :
          null
      }
      {
          stakingRequirementsModal ?
            <Modal
                title="Staking Requirements"
                hasClose={false}
                show={true}
                >
                <div className="flex flex-wrap items-center text-white text-left">
                    {
                        stakeContractDetails.time_lock > 0 ?
                          <div className="mb-3 w-full">
                            The staking period with <span style={{ fontWeight: 'bold' }}>{ tokenStake.symbol }</span> for <span style={{ fontWeight: 'bold' }}>{ tokenReward.symbol }</span> is { stakeContractDetails.time_lock } days. You will be able to claim your reward after this time has elapsed. Until then you will not be able to unstake or claim any rewards.
                            <br /><br />
                            Please be aware that if you stake more <span style={{ fontWeight: 'bold' }}>{ tokenStake.symbol }</span> tokens, the staking period is reset.
                          </div>
                        :
                        <div className="mb-3 w-full">
                          {stakeContractDetails.amount}% of your <span style={{ fontWeight: 'bold' }}>{tokenStake.name}</span> balance is the maximum that can be staked. If you stake {stakeContractDetails.amount}% of your <span style={{ fontWeight: 'bold' }}>{tokenStake.name}</span> and sell any remaining in your wallet, you will lose any unclaimed <span style={{ fontWeight: 'bold' }}>{tokenReward.name}</span>!
                          </div>
                    }
                    <div className="w-full">
                      <Button
                        className="w-full anji-colorful"
                        onClick={approveToken}
                      >
                        Okay, Understood
                      </Button>
                    </div>
                </div>
            </Modal>
          :
          null
      }
      {
          unstakeRewardLoss ?
            <Modal
                title="Warning"
                hasClose={true}
                onClose={cancelUnstake}
                show={true}
                >
                <div className="flex flex-wrap items-center text-white text-left">
                    <div className="mb-3 w-full">
                      It looks like your current { tokenStake.symbol } balance being held is less than the amount being staked. In order to claim your { tokenReward.symbol } reward you must first rectify this otherwise your rewards shall be lost and forfeited.
                    </div>
                    <div className="mb-3 w-full">
                      Are you sure you want to continue and unstake your { tokenStake.symbol }?
                    </div>
                    <div className="mb-3 w-full flex">
                      <Button
                        className="w-1/2 anji-green mr-2"
                        onClick={cancelUnstake}
                      >
                        No
                      </Button>
                      <Button
                        className="w-1/2 anji-red ml-2"
                        onClick={unstake}
                      >
                        Yes
                      </Button>
                    </div>
                </div>
            </Modal>
          :
          null
      }
    </>
  )
}
