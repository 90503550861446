import { BNB_ADDR, ROUTERS } from '../constants/contracts'
import React, { useEffect, useState } from 'react'
import { useImportTokenModalToggle, useModalOpen, useWalletTokenModal } from '../state/application/hooks'

import { ApplicationModal } from '../state/application/actions'
import Button from '../components/Button'
import { ETHER } from '@pancakeswap/sdk'
import { FaRegTimesCircle } from 'react-icons/fa'
import ImportTokenModal from './ImportModal'
import Modal from '../components/Modal'
import { Tokens } from '../constants/tokens'
import { Tokens as TokensCls } from '../constants/Token'
import { isAddress } from '../utils'
import { sortByName } from '../utils/sort'
import styled from 'styled-components'
import { useContract } from '../hooks/useContract'
import { useToken } from '../hooks/Tokens'

const Option = styled.div`
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), #4E555D;
  background-blend-mode: soft-light, normal;
  box-shadow: inset 2.5px 2.5px 5px #35373E;
  border-radius: 20px;
  border: 2px solid rgba(144, 239, 255, 0.1);
  &:hover {
    border: 2px solid rgba(144, 239, 255, 0.5);
  }
  ${({ active }) => active && 'border: 2px solid rgba(144, 239, 255, 1);'}
  display: flex;
  align-items: center;
  padding: 0.25rem 0.55em;
  margin: 5px;
  min-width: 90px;

  cursor: pointer;
`

const CustomInput = styled.input`
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), rgb(78, 85, 93);
    background-blend-mode: soft-light, normal;
    box-shadow: rgb(53 55 62) 2.5px 2.5px 5px inset;
    border-radius: 20px;
    border: 2px solid rgb(83 101 104);
    color: white;

    &:hover {
        border: 2px solid rgba(144, 239, 255, 0.5);
    }
`

const ClearText = styled.div`
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
`

export default function WalletTokenModal() {
  const routerCustom = useContract(ROUTERS.factory.address, ROUTERS.factory.abi, true)
  const toggleImportTokenModal = useImportTokenModalToggle()
  const [slippagedToken, setSlippagedToken] = useState()
  const [selectedToken, setSelectToken] = useState()
  const [total, setTotal] = useState([])
  const [tokensFromStore, setTokensFromStore] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [slippage, setSlippage] = useState('11')
  const walletTokenModalOpen = useModalOpen(ApplicationModal.SELECT_TOKEN_FOR_WALLET)
  const toggleWalletTokenModal = useWalletTokenModal()
  // const allTokens = useAllTokens()
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  let itemData = isAddressSearch ? (searchToken ? [searchToken] : []) : [...sortByName(tokensFromStore)]
  let currentTokens = [...tokensFromStore.map(_token => _token.address), ...Tokens.list.map(_token => _token.address)]

  const useLocalstorageChange = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
    else
      localStorage.setItem('customToken', '[]')
  }
  const handleFirstStore = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
    else
      localStorage.setItem('customToken', '[]')
  }
  useEffect(() => {
    handleFirstStore()
    document.addEventListener(
      'storageChange',
      useLocalstorageChange,
      false
    )
    return () => document.removeEventListener('storageChange', useLocalstorageChange)
  }, [])
  useEffect(() => {
    if(walletTokenModalOpen){
      setSelectToken(undefined)
      setSearchQuery('')
    }
  }, [walletTokenModalOpen])

  const getTokenIcon = async (address) => {
      const logo = document.createElement('img'),
        src = `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`;

      return new Promise((resolve, reject) => {
        logo.addEventListener('error', (er) => {
            resolve('/images/unknown-token.png');
        });

        logo.addEventListener('load', () => {
            resolve(src);
        });

        logo.src = src;
      });
  };
  const addDefaultSrc = (e) => {
    e.target.src = '/images/unknown-token.png'
  }

  return (
    <>
      <Modal
        title="Select a token"
        hasClose={true}
        onClose={toggleWalletTokenModal}
        show={walletTokenModalOpen}
      >
        <div className='max-h-96 overflow-auto flex flex-col'>
          <div className='pl-3 pr-3 mb-3 rounded-md pb-5'>
            <CustomInput type='text' className='w-full border-none outline-none rounded-3xl px-3 pt-1 pb-1' value={searchQuery} onChange={(e) => {
              setSelectToken(undefined)
              setSearchQuery(e.target.value)
            }} placeholder="Custom Token (Contract Address)" />
            <ClearText className="cursor-pointer flex items-center justify-end pt-2 text-md text-white" onClick={() => {
              setSelectToken(undefined)
              setSearchQuery('')
            }} >
              <FaRegTimesCircle className="mr-1" />
              <span>Clear</span>
            </ClearText>
          </div>
          <div className='pl-3 pr-3 mb-3 rounded-md pb-5'>
            <CustomInput type='number' className='w-full border-none outline-none rounded-3xl px-3 pt-1 pb-1' value={slippage} onChange={(e) => setSlippage(e.target.value)} placeholder="Slippage" min="1" />
          </div>
          {
              searchToken && currentTokens.some(address => searchToken.address.toLowerCase() === address.toLowerCase()) ?
                <div className='flex justify-end mb-3'>
                    <div className='text-red-300 text-xs'>{ searchToken.name } has already been added.</div>
                </div>
                :
                null
          }
          <div className="flex flex-wrap items-center text-white mb-5 overflow-auto">
            {
              searchToken && itemData.map((each, i) =>
                <Option
                  active={each.address === selectedToken?.address}
                  key={i}
                  onClick={() => {
                    let customToken = each
                    if (JSON.parse(localStorage.getItem('customToken')).length === 0) {
                      setTotal([])
                      setSelectToken(customToken)
                    }
                    else {
                      setTotal(JSON.parse(localStorage.getItem('customToken')))
                      setSelectToken(customToken)
                    }
                  }}
                >
                  <img onError={addDefaultSrc} src={each === ETHER ? '/images/coins/bnb.png' : (each.logo ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${each.address}/logo.png` ?? '/images/unknown-token.png')} className="bg-white rounded-full" alt="" width={20} height={20} />
                  <span className="ml-1">{each.symbol}</span>
                </Option>
              )
            }
          </div>
        </div>
        <div className='flex justify-end'>
          <Button
            className="anji-green"
            width="170px"
            height="40px"
            disabled={!slippage || slippage < 1 || !searchQuery || !searchToken || (searchToken && currentTokens.some(address => searchToken.address.toLowerCase() === address.toLowerCase()))}
            onClick={async () => {
              const slippagingToken = searchToken
              slippagingToken.slippage = parseInt(slippage)
              slippagingToken.pair_address = await routerCustom.getPair(BNB_ADDR, searchToken.address)
              slippagingToken.color = '#f1f1f9';
              slippagingToken.logo = await getTokenIcon(slippagingToken.address)
              setSlippagedToken(slippagingToken)
              toggleImportTokenModal()
            }}
          >
            Add custom
          </Button>
        </div>
      </Modal>
      <ImportTokenModal total={total} slippagedToken={slippagedToken} />
    </>
  )
}
