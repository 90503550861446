import BMBO_ABI from '../assets/contracts/bamboo_abi.json'
import { Tokens as TokensCls } from './Token';
import admonkey_logo from '../assets/tokens/admonkey.png'
import anji_logo from '../assets/tokens/anji.png'
import bamboo_logo from '../assets/tokens/bamboo.png'
import bnb_logo from '../assets/tokens/bnb.png'
import busd_logo from '../assets/tokens/busd.png'
import flmt_logo from '../assets/tokens/flmt.png'
import genius_logo from '../assets/tokens/genius.png'
import hkun_logo from '../assets/tokens/hkun.png'
import hunger_logo from '../assets/tokens/hunger.png'
import lifetoken_logo from '../assets/tokens/lifetoken.png'
import munch_logo from '../assets/tokens/munch.png'
import rfg_logo from '../assets/tokens/rfg.png'
import safeenergy_logo from '../assets/tokens/safeenergy.png'
import spnd_logo from '../assets/tokens/panda.png'
import wow_logo from '../assets/tokens/wow.png'
import yummy_logo from '../assets/tokens/yummy.png'

const AnjiTokens = [
    {
        name: 'Anji',
        symbol: 'ANJI',
        address: '0xfc619ffcc0e0f30427bf938f9a1b2bfae15bdf84',
        pair_address: '0xc87d1863F7D8FB4E7DD5c20F2A9a7C0Da4be1751',
        decimals: 9,
        color: '#ed63f6',
        logo: anji_logo,
        rewards: true,
        referral: true,
        slippage: 12,
        tokenomics: [
            { tax: 3, label: 'Rewards in BNB'},
            { tax: 3, label: 'for Liquidity Pool' },
            { tax: 2, label: 'AnjiPool (Charity)' },
            { tax: 2, label: 'Anji Foundation' },
        ],
        stake_deposits: true,
        stake_tokens: [
            {
                symbol: 'BMBO',
                address: '0xB5540425C4606f7B540Cf442f979162eBF2D1573',
                amount: 50,
                info: true
            }
        ],
        socials: [
            { type: 'website', link: 'https://anji.eco/' },
            { type: 'telegram', link: 'https://t.me/anjieco' },
            { type: 'twitter', link: 'https://twitter.com/anji_eco' },
            { type: 'discord', link: 'https://discord.gg/anjieco' },
        ],
        description: "ANJI is a security token built to reward holders for the growth of AnjiEco. Holders will receive BNB from fees paid on AnjiEco's current and future products, as well as a portion of ANJI's transactional volume. Through the protocol, ANJI then also generates contributions to causes that make the world a better place. ANJI shapes the future for a new era in DeFi."
    },
    {
        name: 'Bamboo',
        symbol: 'BMBO',
        address: '0xCf42c22a477f8C18dC187699844d049dFF3D7B8C',
        pair_address: '0xD0ef7b3B9d46A499e7761b3fD22A16C7255481Af',
        decimals: 9,
        color: '#3cd0b1',
        slippage: 11,
        logo: bamboo_logo,
        // stake_deposits: true,
        // stake_tokens: [
        //     {
        //         symbol: 'WOW',
        //         address: '0xede577ac675c85e7b187cb226e41a44da6baf69f',
        //         amount: 50,
        //         info: false,
        //         deprecated: true
        //     }
        // ],
        socials: [
            { type: 'website', link: 'https://www.anji.eco/bamboo' },
            { type: 'telegram', link: 'https://t.me/anjieco' },
            { type: 'twitter', link: 'https://twitter.com/anji_eco' },
            { type: 'discord', link: 'https://discord.gg/anjieco' },
        ],
        description: 'This token is constructed to be dynamic with its hyper-deflationary function. This supports the tokens consistant growth into the future and each transaction has a 10% fee which is evenly distributed. With each transaction an amount of BNB is collected and used for planting trees with the foundation OneTreePlanted, allowing for carbon neutral transactions. This creates a powerful cryptocurrency, ready for adoption by businesses not willing to use energy heavy alternatives. More features will be revealed with future developments.',
        tokenomics: [
            { tax: 2, label: 'Liquidity Acquisition' },
            { tax: 2, label: 'Burned Forever'},
            { tax: 2, label: 'Tree Planting' },
            { tax: 2, label: 'Expenses' },
            { tax: 2, label: 'Staking' },
        ],
    },
    {
        name: 'SafePanda',
        symbol: 'SPND',
        address: '0x75e3CF3DC6748ff6c92FE77646bE7d2fdFdFA623',
        pair_address: '0xfbF772E5497f1Dd5bF2a002F079b267EcfDfbbAB',
        decimals: 9,
        color: '#7CF4D7',
        pcs: 1,
        slippage: 8,
        logo: spnd_logo,
        deprecated: true,
        stake_tokens: [
            {
                symbol: 'BMBO',
                address: '0x4510e3ac69574f3dfdb43139e97773b542c386e1',
                abi: BMBO_ABI,
                legacy_stake: true,
                deprecated: true
            }
        ],
        socials: [
            { type: 'website', link: 'https://anji.eco/' },
            { type: 'telegram', link: 'https://t.me/anjieco' },
            { type: 'twitter', link: 'https://twitter.com/anji_eco' },
            { type: 'discord', link: 'https://discord.gg/anjieco' },
        ],
        tokenomics: [
            { tax: 4, label: 'Liquidity Acquisition' },
            { tax: 3, label: 'Static Rewards' },
            { tax: 2, label: 'Panda Adoptions'},
            { tax: 1, label: 'Expenses'}
        ],
        description: 'SafePanda thrives in the Anji ecosystem. A token that is beautifully designed to reward long term holders. Wallets are forever growing, thanks to static rewards and the option to stake for Bamboo. With a 10% transaction fee, a portion of each transaction goes towards adopting Pandas with the WWF. As our future DEX grows with charity tokens joining, SafePanda will lead the way for a new breed of investors. An investor that not only cares about their profit margins, but one that wants to have a positive impact while reaching their financial goals.'
    },

    {
        name: 'AnjiT',
        chainId: 97,
        symbol: 'ANJIT',
        address: '0x6ED1599fc5C2965184a1493062Dd086B74d6E2a9',
        pair_address: '0x5b7a8db642a283bc36cb897ca09b4aad7ef81973',
        pair_flipped: true,
        decimals: 18,
        color: '#ed63f6',
        logo: anji_logo,
        rewards: true,
        slippage: 12,
        referral: true,
        tokenomics: [
            { tax: 3, label: 'Rewards in BNB'},
            { tax: 3, label: 'for Liquidity Pool' },
            { tax: 2, label: 'AnjiPool (Charity)' },
            { tax: 2, label: 'Anji Foundation' },
        ],
        socials: [
            { type: 'website', link: 'https://anji.eco/' },
            { type: 'telegram', link: 'https://t.me/anjieco' },
            { type: 'twitter', link: 'https://twitter.com/anji_eco' },
            { type: 'discord', link: 'https://discord.gg/anjieco' },
        ],
        description: "ANJI is a security token built to reward holders for the growth of AnjiEco. Holders will receive BNB from fees paid on AnjiEco's current and future products, as well as a portion of ANJI's transactional volume. Through the protocol, ANJI then also generates contributions to causes that make the world a better place. ANJI shapes the future for a new era in DeFi."
    },
    {
        name: 'QDropT',
        chainId: 97,
        symbol: 'QDROPT',
        address: '0xa1Ebb2B45194C415Dad4D4dCe91eD61f042fef96',
        pair_address: '0x6c804f927efac19c1fe52c547321b9c4d3c9aab0',
        pair_flipped: true,
        decimals: 18,
        color: '#ed63f6',
        logo: anji_logo,
        rewards: true,
        referral: true,
        slippage: 12,
        tokenomics: [
            { tax: 3, label: 'Rewards in BNB'},
            { tax: 3, label: 'for Liquidity Pool' },
            { tax: 2, label: 'AnjiPool (Charity)' },
            { tax: 2, label: 'Anji Foundation' },
        ],
        socials: [
            { type: 'website', link: 'https://anji.eco/' },
            { type: 'telegram', link: 'https://t.me/anjieco' },
            { type: 'twitter', link: 'https://twitter.com/anji_eco' },
            { type: 'discord', link: 'https://discord.gg/anjieco' },
        ],
        description: "ANJI is a security token built to reward holders for the growth of AnjiEco. Holders will receive BNB from fees paid on AnjiEco's current and future products, as well as a portion of ANJI's transactional volume. Through the protocol, ANJI then also generates contributions to causes that make the world a better place. ANJI shapes the future for a new era in DeFi."
    },
];

const SwapTokens = [
    {
        name: 'AdMonkey',
        symbol: 'ADM',
        address: '0x9eeb03bbdef40980e16e6f4332f486d991d11b84',
        pair_address: '0xF4110Bebc0bdB3319Fbbb6a507B9aEBd717d6F86',
        decimals: 12,
        color: '#fcc400',
        slippage: 10,
        sell_slip: 14,
        logo: admonkey_logo,
        socials: [
            { type: 'website', link: 'https://admonkey.network/' },
            { type: 'telegram', link: 'https://t.me/admonkeytoken' },
            { type: 'twitter', link: 'https://twitter.com/AdMonkeyToken' },
            { type: 'instagram', link: 'https://www.instagram.com/admonkeytoken' }
        ],
        description: 'AdMonkey strives to offer a cutting edge crypto AdTech platform where users can create and manage CPC/CPM digital ad campaigns for display across our network of publishers websites. Future proof development is the primary focus, introducing a crypto-first AI focused platform within a $50B/year industry.',
        tokenomics: [
            { tax: 6, label: 'Reward Pool' },
            { tax: 1, label: 'Marketing'},
            { tax: 1, label: 'Liquidity Pool' },
        ],
        sell_tokenomics: [
            { tax: 8, label: 'Reward Pool' },
            { tax: 2, label: 'Marketing'},
            { tax: 2, label: 'Liquidity Pool' },
        ],
    },
    {
        name: 'Hunger Token',
        symbol: 'HUNGER',
        address: '0x02052B756Bf368eFb177B6Ec7F960ef8F67f7e1d',
        pair_address: '0x6809e74A2C3E0a0DfCb90A86c1f1Ca1793445D41',
        decimals: 9,
        color: '#fcc400',
        slippage: 18,
        logo: hunger_logo,
        socials: [
            { type: 'website', link: 'https://thehungertoken.com/' },
            { type: 'telegram', link: 'https://t.me/TheHungerToken' },
            { type: 'twitter', link: 'https://twitter.com/TheHungerToken' },
            { type: 'youtube', link: 'https://www.youtube.com/channel/UCYUx9232jzkDlecXbRdwu8w' }
        ],
        description: 'Hunger Token is a cryptocurrency which aims to provide investors more ways to grow their investments by bringing Gaming-NFT-Donations-Competitions-Challenges into one platform called Favour Chain, One of the initial other earning way than reflections is Lucky-Shot feature which gives buyers a chance to earn the whole Lucky-Shot pool ( being filled 1% of every tx ) with a buy of 10-15$ worth HUNGER. HUNGER aims also to fight with World Hunger and increase the awareness of the Hunger Problem by not only direct donations but also Favour Chain funding which will encourage more people to get into the network to enjoy & earn and be aware of the Hunger Problem. Doxxed Team with KYC. ',
        tokenomics: [
            { tax: 3, label: 'Charity' },
            { tax: 3, label: 'Marketing'},
            { tax: 2, label: 'Redistribution to Holders'},
            { tax: 2, label: 'Liquidity Pool' },
            { tax: 1, label: 'Lucky Shot Pool' },
            { tax: 1, label: 'Buyback and Burn' },
        ],
    },
    {
        name: 'HakunaMatata',
        symbol: 'HKUN',
        address: '0xbb2fa5b2d19209f4cf50cf745efc32641a7c9fb1',
        pair_address: '0x6f03FE42CC15Afca15e0046b030307370b53B8AF',
        decimals: 9,
        slippage: 13,
        sell_slip: 16,
        logo: hkun_logo,
        socials: [
            { type: 'website', link: 'https://hakunamatatatoken.com/' },
            { type: 'telegram', link: 'https://t.me/hkuntoken' },
            { type: 'twitter', link: ' https://twitter.com/HKUNtoken' },
            { type: 'facebook', link: 'https://www.facebook.com/hkuntoken' },
            { type: 'instagram', link: 'https://www.instagram.com/hkuntoken/' },
            { type: 'youtube', link: 'https://www.youtube.com/channel/UC-UUoGWO_MCcPusXptzCmkA' },
            { type: 'discord', link: 'https://discord.gg/Y4gUSgU7' }
        ],
        tokenomics: [
            { tax: 4, label: 'Burn' },
            { tax: 3, label: 'Liquidity Pool' },
            { tax: 2, label: 'Marketing' },
            { tax: 1, label: 'Charity' },
        ],
        sell_tokenomics: [
            { tax: 4, label: 'Burn' },
            { tax: 3, label: 'Rewards' },
            { tax: 3, label: 'Liquidity Pool' },
            { tax: 2, label: 'Marketing' },
            { tax: 1, label: 'Charity' },
        ],
        description: 'HakunaMatata $HKUN Token is a Binance Smart Chain built on the BEP-20 protocol. “BEP20 is a token standard on Binance Smart Chain that extends ERC-20 and BEP-2 tokens, providing additional functionality that allows you to move your tokens between different blockchains and more”. We have developed the token to build a widespread consensus on the coexistence of financial freedom and charitable drivers in a unique ecosystem that extends the traditional balances of cryptocurrencies. Along with this, HakunaMatata will be entering the gamefi space by introducing its very own Gas-free game with scholarships and also its own Music and Arts NFT marketplace which will have a charity based focus.'
    },
    {
        name: 'Genius Coin',
        symbol: 'GENIUS',
        address: '0xba5b0408b0645ec091b3bb76ee793091a9399bf2',
        pair_address: '0xBF7A568BEebEA1002541b025667C25379D0b592B',
        decimals: 9,
        slippage: 13,
        logo: genius_logo,
        socials: [
            { type: 'website', link: 'https://www.genius-coin.com/' },
            { type: 'telegram', link: 'https://t.me/genius_coin_chat' },
            { type: 'twitter', link: 'https://twitter.com/genius_coin' },
            { type: 'youtube', link: 'https://www.youtube.com/c/GeniusCoin' }
        ],
        description: 'Genius Coin is a deflationary Token created by a team of experts (dev, trader, marketer, economist) and cryptocurrency specialists that aims to literally change the world. It was made to revolutionize the financial system via an eco friendly platform.',
        tokenomics: [
            { tax: 4, label: 'Liquidity Pool' },
            { tax: 3, label: 'Static Rewards' },
            { tax: 2, label: 'Genius Wallet' },
            { tax: 1, label: 'Marketing and Development' },
        ]
    },
    {
        name: 'Munch',
        symbol: 'MUNCH',
        address: '0xC70636a779118e57E1c6fdAfDd1f919Fae912d2f',
        pair_address: '0x9FF68152A7b8a7938595b80FB9b1980557090dF3',
        logo: munch_logo,
        decimals: 9,
        color: '#EC347A',
        slippage: 7,
        socials: [
            { type: 'website', link: 'https://munchproject.io/' },
            { type: 'telegram', link: 'https://t.me/munchtokencom' },
            { type: 'twitter', link: 'https://twitter.com/munchtoken' },
            { type: 'instagram', link: 'https://www.instagram.com/munchtoken/' },
            { type: 'reddit', link: 'https://www.reddit.com/r/MunchToken/' },
            { type: 'discord', link: 'discord.gg/Ang347V8' },
        ],
        description: 'MUNCH project has created an ecosystem for crypto to interact with charitable and worthwhile projects, and enable people to have direct control over how their transactions can impact these causes. The MUNCH donation ecosystem also enables other crypto projects to interact with the charitable projects we introduce to crypto donations. By making donations a fraction of transactions, empowering the community to be part of the process, and removing questions of trust or traceability, MUNCH removes psychological and logistical barriers that commonly prevent donating directly.',
        tokenomics: [
            { tax: 3, label: 'Charity' },
            { tax: 2, label: 'Marketing and Development' },
            { tax: 2, label: 'Static Rewards' },
        ]
    },
    {
        name: 'Safe Energy',
        symbol: 'ENERGYX',
        address: '0xBBe899c61198D1826a43e20ea19efC46E50c2B00',
        pair_address: '0xC4f241705D6A06629FF0B42564af8c1864011Ac5',
        decimals: 8,
        color: '#0ACEFE',
        slippage: 12,
        logo: safeenergy_logo,
        socials: [
            { type: 'website', link: 'https://safeenergyx.io' },
            { type: 'facebook', link: 'https://www.facebook.com/SafeEnergyX' },
            { type: 'telegram', link: 'https://t.me/OfficialSafeEnergyX' },
            { type: 'twitter', link: 'https://twitter.com/OfficialEnergyX' },
            { type: 'instagram', link: 'https://www.instagram.com/officialsafeenergy/' },
        ],
        description: '$ENERGYX is a community-driven deflationary token designed to raise awareness to the current state of the planet, as well as the critical importance of harnessing renewable energy for use in our daily lives.',
        tokenomics: [
            { tax: 5, label: 'Static Rewards' },
            { tax: 2.5, label: 'De-minted from BSC blockchain'},
            { tax: 2.5, label: 'Permanent burn address' },
        ]
    },
    {
        name: 'Floki Metaverse',
        symbol: 'FLMT',
        address: '0x4dcf974e99beea60671b515d624356eeb06cea43',
        pair_address: '0xC6D098147cd70B89309c2C8b99dBd982D5363b69',
        decimals: 18,
        slippage: 11,
        color: '#2575FC',
        logo: flmt_logo,
        socials: [
            { type: 'website', link: 'https://flokimetaverse.io/' },
            { type: 'telegram', link: 'https://t.me/flokimetaverse' },
            { type: 'twitter', link: 'https://twitter.com/flokimetaverse?s=21' },
        ],
        description: 'NFTS and Metaverse Access for the Everyday people. Whilst Earning Passive Income in $FLOKI.',
        tokenomics: [
            { tax: 4, label: 'Floki Rewards' },
            { tax: 4, label: 'Marketing' },
            { tax: 2, label: 'Auto Liquidity'},
        ]
    },
    {
        name: 'World of Waves',
        symbol: 'WOW',
        address: '0x31353e2826cc4402735e55a175a75ce2569b4226',
        pair_address: '0xA6769994dbA50619c534DF1eB5ab032E032e84b3',
        decimals: 9,
        color: '#0ACEFE',
        slippage: 12,
        logo: wow_logo,
        // stake_deposits: false,
        // stake_disabled: true,
        // stake_tokens: [
        //     {
        //         symbol: 'WOW',
        //         address: '0x00198e7db44DB50bb3069F7Ee7d8E9aA6F3763aA',
        //         amount: 100,
        //         time_lock: 90,
        //     }
        // ],
        socials: [
            { type: 'website', link: 'https://wow.surf/' },
            { type: 'telegram', link: 'https://t.me/world_of_waves' },
            { type: 'twitter', link: 'https://twitter.com/world_of_waves' },
            { type: 'instagram', link: 'https://www.instagram.com/worldofwavesofficial/' },
            { type: 'youtube', link: 'https://youtube.com/channel/UCQ_IhegDFNM6ZnwJ3fsg3Ig' }
        ],
        description: '$WOW is a crypto token built on the Binance Smart Chain. $WOW tokens has a built-in tax that is initiated with every transaction; this transactional tax is 11% and is broken down in the following way: 3.3% is redistributed back to all holders, 3.3% to the liquidity pool and 4.4% to the $WOW charity wallet. As the charity wallet grows, funds are extracted monthly for donations towards NGOs around the world to support their cause. The $WOW team will always be the most transparent developer team in the market with its investors.',
        tokenomics: [
            { tax: 4.4, label: 'Charity' },
            { tax: 3.3, label: 'Rewards'},
            { tax: 3.3, label: 'Liquidity Pool' },
        ]
    },
    {
        name: 'Refugees Token',
        symbol: 'RFG',
        address: '0x4477b28e8b797ebaebd2539bb24290fdfcc27807',
        pair_address: '0xcEe097C4132B1AE3B84f9aD3fC43F84B0313952B',
        slippage: 12,
        decimals: 9,
        color: '#41de9b',
        logo: rfg_logo,
        tokenomics: [
            { tax: 5, label: 'Liquidity Pool' },
            { tax: 5, label: 'Static Rewards' },
        ],
        socials: [
            { type: 'website', link: 'https://www.refugeestoken.com' },
            { type: 'telegram', link: 'https://t.me/refugeestokengroup' },
            { type: 'instagram', link: 'https://www.instagram.com/refugees_token/' },
            { type: 'twitter', link: 'https://twitter.com/RefugeesToken' },
            { type: 'facebook', link: 'https://www.facebook.com/refugeestoken' },
        ],
        description: 'Refugees Token has been designed to be a deflationary token that rewards you with tokens simply for holding. Furthermore, the auto-burning utility that happens in each transaction helps the token be more stable by reducing the circulation supply. Last but not least, charity fundraising gives you the chance to both contribute to our cause and get RFG tokens without a tax fee. You can contribute to our charity cause also by purchasing NFTs from our NFT collection.'
    },
    // {
    //     name: '8 Bit Doge',
    //     symbol: 'BITD',
    //     address: '0x003F83da9868AcC151Be89eeFA4D19838FFE5D64',
    //     pair_address: '0xbb82dBC245c1411E9747559A33fF1B06D6d49248',
    //     decimals: 9,
    //     slippage: 10,
    //     color: '#ff8f0e',
    //     logo: bitd_logo,
    //     tokenomics: [
    //         { tax: 3, label: 'Static Rewards' },
    //         { tax: 3, label: 'Liquidity Pool' },
    //         { tax: 2, label: 'Marketing' },
    //         { tax: 1, label: 'Burnt' },
    //     ],
    //     socials: [
    //         { type: 'website', link: 'https://8bitdoge.com/' },
    //         { type: 'telegram', link: 'https://t.me/the8bitdoge' },
    //         { type: 'twitter', link: 'https://twitter.com/8Bit_Doge' },
    //         { type: 'youtube', link: 'https://youtube.com/channel/UCfy9X0wApHLgoEB-9bbBHIg' },
    //         { type: 'discord', link: 'https://discord.gg/Za46bFX5gp' }
    //     ],
    //     description: 'This token is part of the new cryptographic era, starting nostalgically from the revolution in the video game industry in 1980 and adding pixel art, thus being one of the most representative tokens of what we once were, combining the best of two eras in this retrogame that will make you win chips in real time. 8bitdoge is a project that includes a modality Play to win being included in a way in which users can really have fun spending hours of entertainment as well as having great profits while playing, collecting nft and using them in the game.'
    // },
    {
        name: 'Life Token v2',
        symbol: 'LTNv2',
        address: '0x167e5455e4c978883b414e7f02c0147eec9a18e9',
        pair_address: '0x6FdA107eAe13670E8A582659d32DE120d7646311',
        decimals: 18,
        slippage: 14,
        sell_slip: 24,
        color: '#0066D8',
        logo: lifetoken_logo,
        tokenomics: [
            { tax: 5, label: 'Redistribution' },
            { tax: 2, label: 'Liquidity Pool' },
            { tax: 2, label: 'Buy Back and Burn' },
            { tax: 2, label: 'Marketing' },
            { tax: 1, label: 'Charity' },
        ],
        sell_tokenomics: [
            { tax: 9.75, label: 'Liquidity Pool' },
            { tax: 7.25, label: 'Redistribution' },
            { tax: 3, label: 'Buy Back and Burn' },
            { tax: 3, label: 'Marketing' },
            { tax: 1, label: 'Charity' },
        ],
        socials: [
            { type: 'website', link: 'https://www.thelifetoken.com/' },
            { type: 'telegram', link: 'https://t.me/LifeTokenTG' },
            { type: 'twitter', link: 'https://twitter.com/the_life_token' },
            { type: 'instagram', link: 'https://www.instagram.com/lifetokenofficial/' },
        ],
        description: 'Life Token V2 is a charity-orientated token on the Binance Smart Chain. Our goal is to aid suicide prevention charities all over the world to help save lives.',
    },
    {
        name: 'Yummy',
        symbol: 'YUMMY',
        address: '0xb003c68917bab76812797d1b8056822f48e2e4fe',
        pair_address: '0x09ae75e7884F347103dc0F586331611Da8b7B824',
        decimals: 9,
        slippage: 11,
        color: '#f47d14',
        logo: yummy_logo,
        tokenomics: [
            { tax: 3, label: 'Reflections to holders' },
            { tax: 3, label: 'Growth fund or Burn address' },
            { tax: 1, label: 'Liquidity Pool' },
            { tax: 1, label: 'Charity' },
        ],
        socials: [
            { type: 'website', link: 'https://yummycrypto.com/' },
            { type: 'telegram', link: 'https://t.me/yummycoin' },
            { type: 'facebook', link: 'https://www.facebook.com/OfficialYummyCoin', },
            { type: 'twitter', link: 'https://twitter.com/YummyCrypto' },
            { type: 'instagram', link: 'https://www.instagram.com/yummycharity/' },
            { type: 'reddit', link: 'https://www.reddit.com/r/yummycoin/', },
        ],
        description: 'Yummy Crypto is a project with a clear vision to deliver value to holders via Growth Fund and to help solve some of the world\'s most pressing issues.',
    }
];

export const BaseTokens = [
    {
        name: 'BNB',
        symbol: 'BNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        pair_address: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
        decimals: 18,
        logo: bnb_logo,
        color: '#EBA10F',
    },
    {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        pair_address: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
        decimals: 18,
        logo: busd_logo,
        slippage: 1,
        color: '#EBA10F'
    },

    {
        name: 'BNB',
        chainId: 97,
        symbol: 'BNB',
        address: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
        pair_address: '0x85EcDcdd01EbE0BfD0Aba74B81Ca6d7F4A53582b',
        decimals: 18,
        logo: bnb_logo,
        color: '#EBA10F',
    }
]

export const Tokens = new TokensCls([
    ...AnjiTokens,
    ...SwapTokens
        .filter((a) => !a.name.match(/^\d+/))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    ...SwapTokens
        .filter((a) => a.name.match(/^\d+/))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    ...BaseTokens,
], 56);
