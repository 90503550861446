import React, { useEffect, useState } from 'react'
import { useImportTokenModalToggle, useModalOpen } from '../state/application/hooks'

import { ApplicationModal } from '../state/application/actions'
import Button from '../components/Button'
import Modal from '../components/Modal'
import styled from 'styled-components'

const PurpleText = styled.div`
  color: rgb(40, 13, 95);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
`

const WhiteText = styled.div`
  color: white;
  font-size: ${({ size }) => size}px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
`

const ScanLink = styled.a`
  color: #2aaf93;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`

export default function ImportTokenModal({ total, slippagedToken }) {
    const importTokenModalOpen = useModalOpen(ApplicationModal.IMPORT_TOKEN)
    const toggleImportTokenModal = useImportTokenModalToggle()
    const [understand, setUnderstand] = useState(false)
    useEffect(() => {
        importTokenModalOpen === true && setUnderstand(false)
    }, [importTokenModalOpen])
    return (
        <Modal
            title="Import a token"
            hasClose={true}
            onClose={toggleImportTokenModal}
            show={importTokenModalOpen}
        >
            <div className='flex p-4 rounded-md border-solid border-2 border-yellow-300' style={{ background: 'rgba(255, 247, 235)' }}>
                <div className='mr-3'>
                    <svg viewBox="0 0 24 24" fill="rgb(255, 178, 55)" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z"></path></svg>
                </div>
                <PurpleText>
                    Anyone can create a BEP20 token on BSC with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.<br /><br />
                    If you purchase an arbitrary token, you may be unable to sell it back.
                </PurpleText>
            </div>
            <div className='mt-3 flex p-1 items-center rounded-md border-solid border-2 border-pink-500'>
                <div className='mr-3'>
                    <svg viewBox="0 0 24 24" fill="rgb(255, 178, 55)" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z"></path></svg>
                </div>
                <WhiteText size={12}>
                    Unknown source
                </WhiteText>
            </div>
            <WhiteText className='mt-3' size={16}>
                {slippagedToken?.name} ({slippagedToken?.symbol})
            </WhiteText>
            <div className='flex justify-between items-center'>
                <WhiteText className='mt-3' size={16}>
                    {slippagedToken?.address?.length > 16 ? `${slippagedToken?.address?.slice(0, -8)}...` : slippagedToken?.address}
                </WhiteText>
                <ScanLink href={`https://bscscan.com/address/${slippagedToken?.address}`} target="_blank">
                    (View on BscScan)
                </ScanLink>
            </div>
            <div className='mt-3 flex justify-between items-center'>
                <WhiteText className='flex items-center' size={16}>
                    <div className='flex mr-2'>
                        <input type='checkbox' checked={understand} onChange={(e) => setUnderstand(!understand)} />
                    </div>
                    <div style={{ 'cursor': 'pointer' }} onClick={(e) => setUnderstand(!understand)}>
                        I understand
                    </div>
                </WhiteText>
                <Button
                    width='100px'
                    className='anji-green'
                    disabled={!understand}
                    onClick={() => {
                        let currentStored = localStorage.getItem('customToken');
                        let totalTmp = [];
                        if (currentStored) {
                            try {
                                currentStored = JSON.parse(currentStored);
                                console.log(currentStored);
                                totalTmp = currentStored;
                            } catch (err) {
                                //
                            }
                        }

                        totalTmp.push(slippagedToken)
                        localStorage.setItem('customToken', JSON.stringify(totalTmp))
                        toggleImportTokenModal()
                    }}
                >
                    <div className='flex justify-center w-full'>Import</div>
                </Button>
            </div>
        </Modal>
    )
}
