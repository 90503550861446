import { AiOutlineArrowRight, AiOutlineFileProtect } from 'react-icons/ai'
import { FaDiscord, FaExternalLinkSquareAlt, FaInstagram, FaPaperPlane, FaPlusCircle, FaTwitter, FaYoutube } from 'react-icons/fa';
import React, { useEffect, useState } from 'react'
import { formatBalance, getBalance, getContract, getPrice, isRightNetwork } from '../../utils/web3'
import { formatValue, toFloat } from '../../utils/number'
import { useBlockNumber, useWalletTokenModal } from '../../state/application/hooks'

import ANJI_ABI from '../../assets/contracts/anji_abi.json'
import Button from '../../components/Button'
import ComingSoonPanel from '../../components/ComingSoonTokens';
import { IoClose } from 'react-icons/io5';
import Modal from '../../components/Modal'
import { Pie } from "react-chartjs-2";
import { Switch } from '@headlessui/react'
import { Tokens } from '../../constants/tokens'
import { Tokens as TokensCls } from '../../constants/Token';
import _ from 'lodash'
import { ethers } from 'ethers'
import { setTx } from '../../state/tx/actions'
import { sortByName } from '../../utils/sort';
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'

const WalletContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 20px;
  position: relative;
  margin: 10px 0;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TokenAmount = styled.input`
  background: transparent;
  color: white;
  text-align: right;
  width: 200px;
  font-family: 'Roboto Mono', monospace;
  &:focus {
    outline: none;
  }
`
const RewardClaimButton = styled.div`
    height: 35px;

    background: linear-gradient(313.34deg, rgba(0, 0, 0, 0.3) -28.92%, rgba(255, 255, 255, 0.3) 130.82%), #4E555D;
    background-blend-mode: soft-light, normal;
    box-shadow: -5px -5px 10px rgba(250, 251, 255, 12%), 5px 5px 10px #35373E;
    border-radius: 20px;
    cursor: pointer;
    span {
        opacity: 0.8;
    }
    &:hover span {
        opacity: 1;
    }
`;

const AddCustomTokenButton = styled.div`
    cursor: pointer;
    color: white;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
`;

function ToggleDisplay({ toggle, enabled, account, library, network }) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="mr-4 text-white opacity-60">Display All</Switch.Label>
        <Switch
          disabled={!account || !library || !network}
          checked={enabled}
          onChange={() => toggle(!enabled)}
          className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
          style={{ 'backgroundColor': (enabled) ? 'var(--anji-green)' : '#7b7e8d' }}
        >
          <span
            className={`${enabled ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  )
}

function BalancePanel({ icon, title, balance, price, change, info, address, usdPrice, account, library }) {
  const addDefaultSrc = (e) => {
    e.target.src = '/images/unknown-token.png'
  }

  return (
    <WalletContainer
      className="w-full max-w-2xl dark-box-shadow flex flex-row items-center justify-content text-white p-3"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        const customTokens = JSON.parse(localStorage.getItem('customToken'))
        if (!_.some(customTokens, x => x.address.toLowerCase() === address.toLowerCase())) {
          info()
        }
      }}
    >
      <img onError={addDefaultSrc} src={icon ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png` ?? '/images/unknown-token.png'} width={48} height={48} alt="" className="mr-3 dark-box-shadow rounded-3xl" />
      <div
        className="flex flex-col flex-grow items-center justify-start text-left"
      >
        <span className="w-full">{title}</span>
        <span className="w-full text-xs number-font opacity-60">{formatValue((Math.trunc(toFloat(balance) * 100) / 100))}</span>
      </div>
      <div
        className="flex flex-col items-center justify-end text-right"
      >
        <span className="w-full number-font">${formatValue((Math.trunc(toFloat(price) * 100) / 100).toFixed(2))}</span>
        {
            account && library ?
            <div className="w-full flex flex-row justify-end items-center">
                <span className="text-xs number-font opacity-60">${usdPrice}</span>
            </div>
            :
            null
        }
      </div>
    </WalletContainer>
  )
}

const TokenInfoContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
`

function TokenInfoPanel({ token, info, account, chainId, library }) {
  const addDefaultSrc = (e) => {
    e.target.src = '/images/unknown-token.png'
  }
  return (
    <>
      <div className="text-white flex items-center justify-between p-3">
        <div className="flex">
          <img onError={addDefaultSrc} src={token.logo ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${token.address}/logo.png` ?? '/images/unknown-token.png'} className="box-shadow rounded-full mr-1" alt=""
            style={{ width: '30px', height: '30px' }} width={30} height={30} />
          <span className="text-xl">
            {token.name}
          </span>
        </div>
        <span><IoClose onClick={() => info()} style={{ 'cursor': 'pointer' }} /></span>
      </div>
      <div className="px-3 pt-2 text-white flex items-center opacity-60">
        {
          _.map(token.socials, (social, index) => {
            if (social.type === 'telegram') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaPaperPlane /></a>
              )
            } else if (social.type === 'website') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaExternalLinkSquareAlt /></a>
              )
            } else if (social.type === 'twitter') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaTwitter /></a>
              )
            } else if (social.type === 'instagram') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaInstagram /></a>
              )
            } else if (social.type === 'youtube') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaYoutube /></a>
              )
            } else if (social.type === 'discord') {
              return (
                <a className="pr-2" key={index} target="_blank" rel="noreferrer" href={social.link}><FaDiscord /></a>
              )
            }

            return null;
          })
        }
        {
          token.address ?
            <a className="pr-2" target="_blank" rel="noreferrer" href={`https://bscscan.com/token/${token.address}`}><AiOutlineFileProtect /></a>
            :
            null
        }
      </div>
      {
        token.address ?
          <div className="px-3 pt-3 text-white" onClick={() => { navigator.clipboard.writeText(token.address) }}>
            <div>Contract Address: <span className="text-xs">(click to copy)</span></div>
            <div><span className="text-xs opacity-60 select-all">{token.address}</span></div>
          </div>
          :
          null
      }
      <Divider />
      {
        token.rewards && account && isRightNetwork(chainId) ?
          <>
            <RewardsBlock token={token} account={account} chainId={chainId} library={library} />
            <Divider />
          </>
          :
          null
      }
      <div className="pt-3 px-3">
        {
          token.sell_tokenomics ?
            <>
              <div>Buy Tokenomics ({token.buy_tax}%)</div>
              <ul>
                {
                  token.tokenomics.map((tax, index) => {
                    return (
                      <li key={index}><strong className="text-xs mr-2">→</strong>{tax.tax}% {tax.label}</li>
                    )
                  })
                }
              </ul>
              <div className="pt-3">Sell Tokenomics ({token.sell_tax}%)</div>
              <ul>
                {
                  token.sell_tokenomics.map((tax, index) => {
                    return (
                      <li key={index}><strong className="text-xs mr-2">→</strong>{tax.tax}% {tax.label}</li>
                    )
                  })
                }
              </ul>
            </>
            :
            <>
              <div>Tokenomics ({token.buy_tax}%)</div>
              <ul>
                {
                  _.map(token.tokenomics, (tax, index) => {
                    return (
                      <li key={index}><strong className="text-xs mr-2">→</strong>{tax.tax}% {tax.label}</li>
                    )
                  })
                }
              </ul>
            </>
        }
      </div>
      {
        token.description ?
          <>
            <Divider />
            <div className="p-3">
              {token.description}
            </div>
          </>
          :
          <div className="pb-3"></div>
      }
    </>
  )
}

function RewardsBlock({ token, library, account, chainId }) {
  const dispatch = useDispatch()

  const STATUS = {
    READY: 0,
    PENDING: 1
  }
  const contract = getContract(token.address, ANJI_ABI, library, account);
  const [status, setStatus] = useState(STATUS.READY)
  const [fetchIndex, setFetchIndex] = useState(0)
  const tryFetch = () => {
    setFetchIndex(fetchIndex + 1)
  }
  const [rewardAmount, setRewardAmount] = useState('')
  const [rewardAmountUSD, setRewardAmountUSD] = useState('')
  const [checkReInvest, setCheckReInvest] = useState(false)

  useEffect(() => {
    async function fetchRewardAmount() {
      try {
        if (!contract) return
        let upgraded = await contract.getWalletClaimableBNB(account);
        setRewardAmount(ethers.utils.formatEther(upgraded))
        const bnbToken = Tokens.bnb;

        if (bnbToken) {
          let rewardUsd = await getPrice(
            bnbToken.pair_address, bnbToken.decimals, library, bnbToken.symbol)

          let price = bnbToken.calculatePrice({
            [bnbToken.symbol]: ethers.utils.formatEther(upgraded),
          }, {
            [bnbToken.symbol]: rewardUsd
          });

          price = formatValue((Math.trunc(toFloat(price) * 100) / 100).toFixed(2));

          if (0.01 > price) {
            price = '>0.01';
          }

          setRewardAmountUSD(`$${price}`)
        } else {
          setRewardAmountUSD('$0.00');
        }
      } catch (err) {
        setRewardAmount('0')
        setRewardAmountUSD('$0.00');
      }
    }

    if (!library || !account || !isRightNetwork(chainId)) {
      setRewardAmount('0')
      return
    }

    fetchRewardAmount();

  }, [contract, library, account, chainId]);

  const claim = async () => {
    try {
      if (!library || !account || !isRightNetwork(chainId)) return
      if (!contract) return
      setStatus(STATUS.PENDING)

      let tx = await contract.claim();
      tx = await tx.wait(1);
      dispatch(setTx(tx.transactionHash, `BNB Rewards for holding ${token.symbol} claimed`, true))
      tryFetch()
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  };

  const callReInvest = async () => {
    try {
      if (!library || !account || !isRightNetwork(chainId)) return
      if (!contract) return
      setStatus(STATUS.PENDING)

      let earnt = parseFloat(formatValue((Math.trunc(toFloat(rewardAmount) * 100) / 100).toFixed(2)));

      if (earnt >= 0.02) {
        await reInvest();
      } else {
        setCheckReInvest(true)
      }
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
      setStatus(STATUS.READY)
    }
  };

  const cancelReInvest = async () => {
    setCheckReInvest(false)
    setStatus(STATUS.READY)
  }

  const reInvest = async () => {
    setCheckReInvest(false)
    try {
      if (!library || !account || !isRightNetwork(chainId)) return
      if (!contract) return

      let tx = await contract.buyAnjiWithReward();
      tx = await tx.wait(1);

      dispatch(setTx(tx.transactionHash, `BNB Rewards used to buy more ${token.symbol}`, true))
      tryFetch()
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  };

  const donate = async () => {
    try {
      if (!library || !account || !isRightNetwork(chainId)) return
      if (!contract) return
      setStatus(STATUS.PENDING)

      let tx = await contract.donate();
      tx = await tx.wait(1);
      dispatch(setTx(tx.transactionHash, 'BNB Rewards donated to AnjiPool. We thank you for your donation', true))
      tryFetch()
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  };

  return (
    <>
      <TokenArea>
        <div>BNB Rewards</div>
        <TokenAmount placeholder={rewardAmount} disabled={true}></TokenAmount>
      </TokenArea>
      <TokenArea style={{ 'paddingTop': 0 }}>
        <div>&nbsp;</div>
        <TokenAmount placeholder={rewardAmountUSD} disabled={true}></TokenAmount>
      </TokenArea>
      <div className="pt-3 px-3">
        <p>
          Re-invest your BNB rewards at a reduced 5% tax, Donate to the AnjiPool charity fund or claim to your wallet.
        </p>
      </div>
      <div className="flex flex-row items-center justify-center h-10 mt-5 mx-4">
        <RewardClaimButton className="mr-6 m-1 text-white text-sm font-medium flex items-center justify-center flex-1" onClick={callReInvest} disabled={status === STATUS.PENDING}>
          <span>Re-Invest</span>
        </RewardClaimButton>
        <RewardClaimButton className="mr-6 m-1 text-white text-sm font-medium flex items-center justify-center flex-1" onClick={claim} disabled={status === STATUS.PENDING}>
          <span>Claim</span>
        </RewardClaimButton>
        <RewardClaimButton className="m-1 text-white text-sm font-medium flex items-center justify-center flex-1" onClick={donate} disabled={status === STATUS.PENDING}>
          <span>Donate</span>
        </RewardClaimButton>
      </div>
      {
        checkReInvest ?
          <Modal
            title={`Re-Invest Rewards for ${token.symbol}`}
            hasClose={false}
            show={true}
          >
            <div className="flex flex-wrap items-center text-white text-left">
              <div className="w-full">
                Currently your reward amount is {formatValue((Math.trunc(toFloat(rewardAmount) * 100) / 100).toFixed(2))} BNB due to paying of gas and transaction fees we suggest having at least 0.02 BNB in rewards before proceeding.
              </div>
              <div className="flex flex-row items-center justify-center mt-5 mx-4 w-full">
                <div className="flex flex-1 font-medium items-center justify-center m-1 mr-6 text-sm text-white">
                  <Button
                    className="w-full anji-green"
                    onClick={cancelReInvest}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="flex flex-1 font-medium items-center justify-center m-1 mr-6 text-sm text-white">
                  <Button
                    className="w-full anji-colorful"
                    onClick={reInvest}
                  >
                    Please continue
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          :
          null
      }
    </>
  )
}

function getEmptyBalPrices() {
  return [...Tokens.list, ...new TokensCls(JSON.parse(localStorage.getItem('customToken')) ?? [])._tokens].reduce((obj, item) => ({ ...obj, [item.symbol]: 0 }), {});
}

function WalletPage() {
  const { chainId, account, library } = useWeb3React()
  const search = useLocation().search

  const blockNumber = useBlockNumber()

  const [tokensFromStore, setTokensFromStore] = useState([])
  const [balances, setBalances] = useState(getEmptyBalPrices())
  const [prices, setPrices] = useState(getEmptyBalPrices())
  const [tokenInfo, setTokenInfo] = useState('')
  const [displayAll, setDisplayAll] = useState(false)

  const toggleWalletTokenModal = useWalletTokenModal()

  const getCustomTokens = () => {
    let tokens;

    try {
      const storedTokens = JSON.parse(localStorage.getItem('customToken'));
      tokens = new TokensCls(storedTokens.map(customToken => {
        customToken.color = '#f1f1f9';
        return customToken;
      }))._tokens;
    } catch (err) {
      tokens = undefined;
    }

    return tokens;
  };

  const useLocalstorageChange = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(getCustomTokens())
    else
      localStorage.setItem('customToken', '[]')
  }

  const handleFirstStore = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(getCustomTokens())
    else
      localStorage.setItem('customToken', '[]')
  }

  useEffect(() => {
    handleFirstStore()
    document.addEventListener(
      'storageChange',
      useLocalstorageChange,
      false
    )
    return () => {
      document.removeEventListener('storageChange', useLocalstorageChange)
    }
  }, []);

  useEffect(() => {
    // Retrieve token from query param
    const tokenParam = new URLSearchParams(search).get('token') || ''
    const token = Tokens.getToken(tokenParam.toUpperCase())

    if (token) {
      setTokenInfo(token)
    }

    if (!library || !account || !isRightNetwork(chainId)) {
      setBalances(getEmptyBalPrices())
      setPrices(getEmptyBalPrices())
      return
    }

    const totalTokens = [...Tokens.list, ...tokensFromStore]
    const balancePromises = totalTokens.filter(_token => _token.address).map(token => {
      return new Promise((resolve, reject) => {
        async function fetchTokenBal(_token) {
          const address = (_token.is_bnb) ? null : _token.address;

          try {
            let newBalance = await getBalance(account, address, library)
            newBalance = formatBalance(newBalance, _token.decimals)
            resolve({
              // [_token.symbol]: 2
              [_token.symbol]: toFloat(newBalance)
            });
          } catch (e) {
            console.log({ [_token.symbol]: e });
            reject();
          }
        }

        fetchTokenBal(token);
      });
    });

    const tokenPricePromises = totalTokens.filter(_token => _token.address).map(token => {
      return new Promise((resolve, reject) => {
        async function getTokenPrice(_token) {
          try {
            let newPrice = await getPrice(_token.pair_address, _token.decimals, library, _token.symbol)
            if (isNaN(newPrice)) {
              newPrice = 0
            }
            resolve({ [token.symbol]: newPrice });
          } catch (e) {
            console.log({ [_token.symbol]: e });
            reject();
          }
        }

        getTokenPrice(token);
      });
    });

    Promise.all(balancePromises).then(results => {
      let bal = {};
      results.forEach(tokenBalance => {
        bal = {
          ...bal,
          ...tokenBalance,
        };
      });
      setBalances(bal);
    })

    Promise.all(tokenPricePromises).then(results => {
      let price = {};
      results.forEach(tokenPrice => {
        price = {
          ...price,
          ...tokenPrice,
        };
      });
      setPrices(price);
    });

  }, [chainId, account, library, blockNumber, search, tokensFromStore])

  const sortByPrice = (tokens) => {
    return _.orderBy(tokens, x => x.calculatePrice(balances, prices), 'asc')
  }

  const pieOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    animation: false
  }
  const totalTokens = sortByPrice([...Tokens.list, ...tokensFromStore])
  const allTokenPrices = totalTokens.map(_token => _token.calculatePrice(balances, prices));
  const data = {
    labels: totalTokens.map(_token => _token.name),
    datasets: [
      {
        data: allTokenPrices,
        backgroundColor: totalTokens.map(_token => _token.color),
        borderWidth: 0
      }
    ]
  }

  const totalFund = allTokenPrices.reduce((partial_sum, a) => partial_sum + a, 0);

  const Balance = ({ icon, value, color }) => {
    const percent = (Math.trunc(value === 0 ? 0 : value / totalFund * 10000) / 100).toFixed(2);
    const addDefaultSrc = (e) => {
      e.target.src = '/images/unknown-token.png'
    }

    if (percent === '0.00') {
      return null;
    }

    return (
      <div className="flex flex-row items-center justify-start">
        <img onError={addDefaultSrc} src={icon} width={24} height={24} alt="" className="mr-1.5" />
        <span className="number-font text-xs" style={{ color }}>{(Math.trunc(value === 0 ? 0 : value / totalFund * 10000) / 100).toFixed(2)}%</span>
      </div>
    )
  }

  return (
    <>
      {
        !tokenInfo ?
          <>
            <WalletContainer
              className="w-full max-w-2xl dark-box-shadow flex flex-col items-center justify-content text-white p-3"
            >
              {
                totalFund === 0 ?
                  <>
                    <div className="flex justify-between w-full">
                      {
                        account && library && isRightNetwork(chainId) ?
                          <>
                            <AddCustomTokenButton onClick={() => toggleWalletTokenModal()}><FaPlusCircle className="inline-block" /> Custom Token</AddCustomTokenButton>
                            <ToggleDisplay enabled={displayAll} toggle={setDisplayAll} library={library} account={account} network={isRightNetwork(chainId)} />
                          </>
                          :
                          null
                      }
                    </div>
                    <div
                      className="w-full relative" style={{ marginBottom: '-40px' }}
                    >
                      <div className="w-full" style={{ marginBottom: '100%' }} />
                      <div
                        className="absolute left-0 top-0 w-full h-full"
                        style={{
                          padding: '30px 50px 0 50px'
                        }}
                      >
                        <div
                          className="rounded-full w-full"
                          style={{
                            background: "linear-gradient(125deg, var(--platform-primary), var(--platform-secondary))",
                            height: 'calc(100% - 70px)'
                          }}
                        />

                        <div
                          className="absolute rounded-full"
                          style={{
                            width: "calc(100% - 130px)", height: "calc(100% - 130px)",
                            left: "50%", top: "calc(50% - 20px)", transform: "translate(-50%, -50%)",
                            background: "#4E555D"
                          }}
                        >
                          <div className="w-full h-full flex flex-col items-center justify-center text-white opacity-60">
                            <span>Anji Balance</span>
                            <span className="number-font mt-3" style={{ fontSize: 20 }}>$00.00</span>
                            <div className="flex flex-col items-center justify-center mt-6 hidden">
                              <span>Need help</span>
                              <span>getting started?</span>
                              <AiOutlineArrowRight className="cursor-pointer mt-2" size={20} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="flex justify-between w-full">
                      <AddCustomTokenButton onClick={() => toggleWalletTokenModal()}><FaPlusCircle className="inline-block" /> Custom Token</AddCustomTokenButton>
                      <ToggleDisplay enabled={displayAll} toggle={setDisplayAll} library={library} account={account} network={isRightNetwork(chainId)} />
                    </div>
                    <div
                      className="w-full relative"
                      style={{
                        padding: '30px 50px 0 50px'
                      }}
                    >
                      <Pie data={data} options={pieOptions} width={1} height={1} />
                      <div
                        className="absolute rounded-full"
                        style={{
                          width: "calc(100% - 130px)", height: "calc(100% - 60px)",
                          left: "50%", top: "calc(50% + 15px)", transform: "translate(-50%, -50%)",
                          background: "#4E555D"
                        }}
                      >
                        <div className="w-full h-full flex flex-col items-center justify-center text-white">
                          <span>Anji Balance</span>
                          <span className="number-font mt-3" style={{ fontSize: 20 }}>${formatValue((Math.trunc(toFloat(totalFund) * 100) / 100).toFixed(2))}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gap-3 grid grid-cols-3 mt-3 sm:grid-cols-5"
                    >
                      {
                        totalTokens.filter(token => {
                          if (!token.deprecated) {
                            return true;
                          }

                          // If the token is deprecated ONLY display if
                          return Boolean(balances[token.symbol]);
                        })
                        .sort((tokenA, tokenB) => {
                          return tokenB.calculatePrice(balances, prices) - tokenA.calculatePrice(balances, prices);
                        })
                        .map((token, index) => {
                          return (
                            <Balance icon={token.logo ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${token.address}/logo.png` ?? '/images/unknown-token.png'} value={token.calculatePrice(balances, prices)} color={token.color} key={index} />
                          )
                        })
                      }
                    </div>
                  </>
              }
            </WalletContainer>
            {
              Tokens.coming_soon.map((token, index) => {
                return (
                  <ComingSoonPanel key={index} icon={token.logo} title={token.name} symbol={token.symbol} info={() => {
                    if (token.is_bnb || token.is_busd) {
                      return;
                    }
                    setTokenInfo(token)
                  }}></ComingSoonPanel>
                )
              })
            }
            {
              [...Tokens.list.filter(token => {
                // Always display for users not logged in
                if (!library || !account || !isRightNetwork(chainId)) {
                  return !token.deprecated;
                }
                const price = token.calculatePrice(balances, prices)
                if (!displayAll) {
                  if (token.ecoToken || token.is_busd || token.is_bnb) {
                    return true;
                  }
                  // If user has display all off only display tokens that the user holds EXCEPT ANJI
                  return formatValue((Math.trunc(toFloat(price) * 100) / 100).toFixed(2)) !== '0.00';
                } else if (token.deprecated) {
                  return formatValue((Math.trunc(toFloat(price) * 100) / 100).toFixed(2)) !== '0.00';
                }

                // If the token is deprecated ONLY display if there is a balance
                return true;
              }), ...sortByName(tokensFromStore)].map((token, index) => {
                // console.log(_.get(balances, 'RING'), _.get(prices, 'RING'))

                return (
                  <BalancePanel
                    key={index}
                    icon={token.logo}
                    title={token.name}
                    address={token.address}
                    account={account}
                    library={library}
                    balance={balances[token.symbol]}
                    price={token.calculatePrice(balances, prices)}
                    usdPrice={token.usdPrice(prices)}
                    change={100}
                    info={() => {
                      if (token.is_bnb || token.is_busd) {
                        return;
                      }
                      setTokenInfo(token)
                    }}
                  />
                )
              })
            }
          </>
          :
          <TokenInfoContainer
            className="w-full max-w-2xl dark-box-shadow text-white text-left"
          >

            <TokenInfoPanel token={tokenInfo} account={account} chainId={chainId} library={library} info={() => setTokenInfo('')} />
          </TokenInfoContainer>
      }
    </>
  )
}

export default WalletPage
