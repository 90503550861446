import ANJI_REFERRAL from '../assets/contracts/referral.json'
import ANJI_ROUTER_PCSV2_ABI from '../assets/contracts/anji_router_pcsv2_abi.json'
import PCS_V1_ROUTER_ABI from '../assets/contracts/pcsv1_abi.json'
import PCS_V2_FACTORY_ABI from '../assets/contracts/pcsv2_factory_abi.json'
import PCS_V2_ROUTER_ABI from '../assets/contracts/pcsv2_abi.json'
import SPND_ANJI_ABI from '../assets/contracts/upgrade.json'

export const TEST_ROUTERS = {
    anji: {
        address: '0xe56a6d7d9b7c7a3Bf5722aF78ea6973B71C41812',
        abi: ANJI_ROUTER_PCSV2_ABI,
    },
    pcs_v2: {
        address: '0x9ac64cc6e4415144c455bd8e4837fea55603e5c3',
        abi: PCS_V2_ROUTER_ABI
    },
    factory: {
        address: '0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc',
        abi: PCS_V2_FACTORY_ABI,
    }
};

export const ROUTERS = {
    anji: {
        address: '0x4ba3d9fd39552dbaaa7053f001a2ee36ec8b44f0',
        abi: ANJI_ROUTER_PCSV2_ABI,
    },
    pcs_v1: {
        address: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        abi: PCS_V1_ROUTER_ABI,
    },
    factory: {
        address: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        abi: PCS_V2_FACTORY_ABI
    },
    pcs_v2: {
        address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        abi: PCS_V2_ROUTER_ABI,
    },
    anji_referral: {
        address: '0xCDc60f995D4A22eA769F260edA7Ddb09EfA7eff2',
        abi: ANJI_REFERRAL
    }
}

export const UPGRADE_CONTRACT = {
    address: '0x5Ba41eAE2AE8a103B19ffE23384310B065bAF7f3',
    abi: SPND_ANJI_ABI
}

export const BNB_ADDR = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
