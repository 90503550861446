import React, { useEffect, useRef, useState } from 'react'
import { TokenKeys, setCurrentToken, setToken } from '../../state/swap/actions'
import { formatBN, formatBalance, getAllowance, getBalance, getContract, isAddress, isRightNetwork } from '../../utils/web3'
import { formatValue, toFloat, toSignificant } from '../../utils/number'
import { useBlockNumber, useWalletModalToggle } from '../../state/application/hooks'
import { useCurrentKey, useEstimated, useGetOppositeToken, useGetToken, usePancakeSwapVersion, useSwapTokens } from '../../state/swap/hooks'
import { useHistory, useLocation } from 'react-router-dom';

import BEP20_ABI from '../../assets/contracts/bep20_abi.json'
import { BsGear } from 'react-icons/bs'
import Button from '../../components/Button'
import { FiArrowDown } from 'react-icons/fi'
import { ROUTERS } from '../../constants/contracts'
import TokenInput from '../../components/TokenInput'
import TokenSelect from '../../components/TokenSelect'
import { Tokens } from '../../constants/tokens'
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components'
import { useContract } from '../../hooks/useContract'
import { useDispatch } from 'react-redux'
import usePrevious from '../../hooks/usePrevious'
import { useWeb3React } from '@web3-react/core'

const SwapContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Settings = styled.div`
  background: linear-gradient(313.34deg, rgba(0, 0, 0, 0.3) -28.92%, rgba(255, 255, 255, 0.3) 130.82%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 20px;
  position: absolute;
  top: 40px;
  right: 10px;
`
const SettingInput = styled.input`
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), #4E555D;
  background-blend-mode: soft-light, normal;
  box-shadow: inset 2.5px 2.5px 5px #35373E;
  border-radius: 20px;
  text-align: right;
  padding: 3px 8px;
  width: ${props => props.width};
  outline: none;
  font-family: 'Roboto Mono', monospace;
  &.active {
    background: linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%), var(--platform-primary);
    color: white;
  }
`

const TokenMax = styled.div`
  font-size: 11px;
  color: white;
  text-align: left;
  padding: 1rem 1rem 0 1rem;
`
const AddMax = styled.div`
  color: var(--platform-primary-bright);
  cursor: pointer;
`

export default function SwapPage() {
  const { chainId, account, library } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const version = usePancakeSwapVersion()
  const estimated = useEstimated()
  const currentKey = useCurrentKey()
  const tokenA = useGetToken(TokenKeys.TOKEN_A)
  const tokenB = useGetToken(TokenKeys.TOKEN_B)

  let tokenACls = undefined;
  let tokenBCls = undefined;
  if (tokenA) {
    tokenACls = Tokens.customToken(tokenA.address) ? Tokens.customToToken(tokenA) : Tokens.getToken(tokenA.symbol);
    tokenACls.slippage = tokenA.slippage || 11
  }

  if (tokenB) {
    tokenBCls = Tokens.customToken(tokenB.address) ? Tokens.customToToken(tokenB) : Tokens.getToken(tokenB.symbol);
    tokenBCls.slippage = tokenB.slippage || 11
  }
  const swapTokens = useSwapTokens()
  const dispatch = useDispatch()
  const search = useLocation().search;
  const history = useHistory()

  const routerV1 = useContract(ROUTERS.pcs_v1.address, ROUTERS.pcs_v1.abi, true)
  const routerV2 = useContract(ROUTERS.pcs_v2.address, ROUTERS.pcs_v2.abi, true)
  const routerAnji = useContract(ROUTERS.anji.address, ROUTERS.anji.abi, true)

  const STATUS = {
    READY: 0,
    PENDING: 1
  }
  const [status, setStatus] = useState(STATUS.READY)

  const [settingsOpen, toggleSettings] = useState(false)
  const [slippage, setSlipPage] = useState(-1)
  const [defaultSlippage, setDefaultSlipPage] = useState(1)
  const [deadline, setDeadline] = useState(30)
  const prevDeadline = usePrevious(deadline)

  useEffect(() => {
    const stakeParam = new URLSearchParams(search).get('token') || '';
    let referrer = new URLSearchParams(search).get('referrer') || '';
    if(window.sessionStorage.getItem('referrer') !== referrer && (referrer === '' || !referrer))
      referrer = window.sessionStorage.getItem('referrer');
    if(new URLSearchParams(search).get('referrer') === '') referrer = '';
    if(referrer !== '' && referrer !== null) {
      console.log(referrer);
      window.sessionStorage.setItem('referrer', referrer);
      if(stakeParam && stakeParam !== '')
        history.replace({ search: new URLSearchParams({ token: stakeParam, referrer: referrer }).toString() })
      else history.replace({ search: new URLSearchParams({referrer: referrer }).toString() })
    }
    else {
      window.sessionStorage.removeItem('referrer');
    }
  }, [history, search])

  useEffect(() => {
    dispatch(setCurrentToken(TokenKeys.TOKEN_A))
    dispatch(setToken(Tokens.getToken('BNB')))

    // Retrieve token from query param
    const stakeParam = new URLSearchParams(search).get('token') || ''
    const token = Tokens.getToken(stakeParam.toUpperCase())
    if (token && !token.is_bnb) {
      dispatch(setCurrentToken(TokenKeys.TOKEN_B))
      dispatch(setToken(token))
    }
  }, [dispatch, search])

  useEffect(() => {
    const slippage = tokenACls?.sell_slippage || tokenBCls?.slippage || 11;
    setDefaultSlipPage(slippage)

    if (tokenACls && tokenBCls) {
        const token = tokenACls?.symbol !== Tokens.getToken('BNB').symbol ? tokenACls : tokenBCls
        const stakeParam = new URLSearchParams(search).get('token') || ''
        let referrer = new URLSearchParams(search).get('referrer') || '';
        if(window.sessionStorage.getItem('referrer') !== referrer && (referrer === '' || !referrer))
          referrer = window.sessionStorage.getItem('referrer');
        if(stakeParam.toLowerCase() !== token.symbol.toLowerCase()) {
          if(referrer) {
            window.sessionStorage.setItem('referrer', referrer);
            history.replace({ search: new URLSearchParams({ token: token.symbol, referrer: referrer }).toString() })
          }
          else history.replace({ search: new URLSearchParams({ token: token.symbol }).toString() })
        }
    }
  }, [tokenACls, tokenBCls, setDefaultSlipPage, search, history])

  const [price, setPrice] = useState()
  useEffect(() => {
    if (!estimated || !currentKey || !tokenA || !tokenB) return

    const input = currentKey === TokenKeys.TOKEN_A ? tokenA.amount : estimated
    const output = currentKey === TokenKeys.TOKEN_B ? tokenB.amount : estimated

    let newPrice = 0
    if (tokenA.symbol === 'BNB')
      newPrice = toSignificant(toFloat(output) / toFloat(input), 6)
    else
      newPrice = toSignificant(toFloat(input) / toFloat(output), 6)
    setPrice(newPrice)
  }, [estimated, currentKey, tokenA, tokenB, setPrice])

  const [fetchIndex, setFetchIndex] = useState(0)
  const tryFetch = () => {
    setFetchIndex(fetchIndex + 1)
  }

  const swap = async () => {
    try {
      if (!account || !estimated || !currentKey || !tokenA || !tokenB || !tokenACls || !tokenBCls || !routerV1 || !routerV2 || !routerAnji) return

      let router;

      if (version === 1) {
        router = routerV1;
      } else {
        router = (tokenACls.sell_pcs) ? routerV2 : routerAnji;
      }
      const path = [tokenA.address, tokenB.address]
      const timestamp = Math.floor(Date.now() / 1000) + 60 * toFloat(deadline)

      let input = currentKey === TokenKeys.TOKEN_A ? tokenA.amount : estimated
      input = formatBN(input, tokenA.decimals)
      let output = currentKey === TokenKeys.TOKEN_B ? tokenB.amount : estimated
      output = formatBN(output, tokenB.decimals)

      let slip = parseFloat(slippage)

      if (slippage === -1) {
        slip = !tokenACls.is_bnb ? 12 : tokenBCls.slippage || 11
      }

      setStatus(STATUS.PENDING)
      let tx = null
      const overrides = {
        value: input
      }
      let referrer = new URLSearchParams(search).get('referrer') || '';
      let useReferrerMethod = false;
      let storage_accounts = localStorage.getItem('accounts');
      if(storage_accounts){
        JSON.parse(storage_accounts).forEach((item) => { if(item.toLowerCase() === referrer.toLowerCase()) useReferrerMethod = true})
      }
      if(!isAddress(referrer) || !referrer || referrer === '' || account.toLowerCase() === referrer.toLowerCase()) useReferrerMethod = true;

      let minOutput = output.sub(((output.div(100)).mul(slip)))
      if (tokenACls.is_bnb) {
        if (!useReferrerMethod) {
          console.log('Referrer used for wallet (use referral swap method)', referrer);
          tx = await router.referralSwapExactETHForTokensSupportingFeeOnTransferTokens(minOutput, path, account, referrer,  timestamp, overrides)
        } else {
          tx = await router.swapExactETHForTokensSupportingFeeOnTransferTokens(minOutput, path, account, timestamp, overrides)
        }
      } else {
        tx = await router.swapExactTokensForETHSupportingFeeOnTransferTokens(input, minOutput, path, account, timestamp)
      }

      if (tx) {
        tx = await tx.wait(1)
        dispatch(setTx(tx.transactionHash, `Swaped`, true))

        let newToken = currentKey === TokenKeys.TOKEN_A ? tokenA : tokenB
        newToken.amount = ''
        dispatch(setToken(newToken))
        tryFetch()
      }
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  const blockNumber = useBlockNumber()
  const tokenOther = useGetOppositeToken()
  const [balance, setBalance] = useState(0)
  const [tokenBalance, setTokenBalance] = useState(0)
  const [tokenAllowance, setTokenAllowance] = useState(0)

  useEffect(() => {
    async function fetchBalance() {
      try {
        let newBalance = await getBalance(account, null, library)
        newBalance = formatBalance(newBalance)
        setBalance(Math.trunc(toFloat(newBalance) * 100) / 100)
      } catch (e) { console.log(e) }
    }
    async function fetchTokenBalance() {
      try {
        let newBalance = await getBalance(account, tokenOther.address, library)
        newBalance = formatBalance(newBalance, tokenOther.decimals)
        setTokenBalance(toFloat(newBalance))
      } catch (e) { console.log(e) }
    }
    async function fetchTokenAllowance() {
      try {
        const router = version === 1 ? routerV1 : routerAnji
        let newAllowance = await getAllowance(account, router.address, tokenOther.address, library)
        newAllowance = formatBalance(newAllowance, tokenOther.decimals)
        setTokenAllowance(toFloat(newAllowance))
      } catch (e) { console.log(e) }
    }
    if (!library || !account || !isRightNetwork(chainId)) return
    fetchBalance()

    if (!tokenOther) return
    fetchTokenBalance()

    if (!version || !routerV1 || !routerAnji) return
    fetchTokenAllowance()
  }, [chainId, account, library, blockNumber, tokenOther, version, routerV1, routerAnji, fetchIndex])
  const approveToken = async () => {
    try {
      if (!library || !account) return
      if (!version || !routerV1 || !routerAnji) return
      const router = version === 1 ? routerV1 : routerAnji
      const tokenContract = getContract(tokenA.address, BEP20_ABI, library, account)
      if (!tokenContract) return
      let totalSupply = '1000000000000000000000000';
      if (tokenA.decimals === 18) {
        totalSupply = '1000000000000000000000000000000000'
      }
      setStatus(STATUS.PENDING)
      let tx = await tokenContract.approve(router.address, totalSupply)
      tx = await tx.wait(1)
      dispatch(setTx(tx.transactionHash, `${tokenA.symbol} Approved`, true))
      tryFetch()
    } catch (e) {
      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  const checkBalances = () => {
    if (!account || !estimated || !currentKey || !tokenA) return 'Error'

    let input = currentKey === TokenKeys.TOKEN_A ? tokenA.amount : estimated

    if (tokenA.symbol === 'BNB' && input > balance) return 'Insufficient BNB balance'
    if (tokenA.symbol !== 'BNB' && input > tokenBalance) return `Insufficient ${tokenA.symbol} balance`

    return ''
  }

  const tokenAInput = useRef()
  const tokenBInput = useRef()

  const inputRegex = new RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
  const checkAndUpdate = (amount, callback) => {
    const nextUserInput = amount ? amount.toString().replace(/[,]/g, '') : ''
    if (!nextUserInput || inputRegex.test(nextUserInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))) {
      if (callback)
        callback(nextUserInput.toString())
    }
  }

  return (
    <>
      <SwapContainer
        className="w-full max-w-2xl dark-box-shadow"
      >
        <div
          className="px-3 pt-3 text-white flex items-center justify-between"
        >
          <span>Swap</span>
          <BsGear width={20} height={20} className="cursor-pointer icon-btn" onClick={() => toggleSettings(true)} />
        </div>
        <Divider />
        <TokenArea
        >
          <TokenSelect tokenKey={TokenKeys.TOKEN_A} />
          <TokenInput tokenKey={TokenKeys.TOKEN_A} ref={tokenAInput} />
        </TokenArea>
        {
          tokenA &&
          <TokenMax>
            <div className="number-font opacity-60">
              {formatValue(tokenA.symbol === 'BNB' ? balance : tokenBalance, '0.0')} {tokenA.symbol}
            </div>
            <AddMax
              onClick={() => tokenAInput.current.setMax(tokenA.symbol === 'BNB' ? balance : tokenBalance)}
            >
              Add MAX
            </AddMax>
          </TokenMax>
        }
        <Divider>
          <div
            style={{
              background: 'linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #E7EBF0',
              backgroundBlendMode: 'soft-light, normal',
              borderRadius: '4px',
              width: 33,
              height: 26
            }}
            className="box-shadow flex items-center justify-center"
            onClick={swapTokens}
          >
            <FiArrowDown size={12} className="m-1" />
          </div>
        </Divider>
        <TokenArea
        >
          <TokenSelect tokenKey={TokenKeys.TOKEN_B} />
          <TokenInput tokenKey={TokenKeys.TOKEN_B} ref={tokenBInput} />
        </TokenArea>
        {
          tokenB &&
            <TokenMax>
              <div className="number-font opacity-60">
                {formatValue(tokenB.symbol === 'BNB' ? balance : tokenBalance, '0.0')} {tokenB.symbol}
              </div>
            </TokenMax>
        }
        <Divider />
        <div
          className="p-3 flex items-center justify-between flex-col"
        >
          {
            version > 0 && estimated ?
              <div className="flex items-center justify-between px-2 w-full text-white text-sm number-font" style={{ fontSize: 11 }}>
                <span>PCS V{version}</span>
                <span>1 BNB = {formatValue(price)} {tokenA?.symbol === 'BNB' ? tokenB?.symbol : tokenA?.symbol}</span>
              </div>
              :
              null
          }
          {
            tokenACls && tokenBCls ?
              <div className="mb-5 text-right px-2 w-full text-white text-sm number-font opacity-60" style={{ fontSize: 11 }}>
                <span>
                  Slippage {slippage === -1 ? (tokenACls?.is_bnb ? tokenBCls?.slippage : tokenACls?.sell_slippage) : slippage}%
                </span>
              </div>
              :
              null
          }
          {
            account && isRightNetwork(chainId) && estimated && !checkBalances() ?
              <Button
                className="w-full anji-colorful"
                disabled={status === STATUS.PENDING || (tokenA && tokenA.symbol !== 'BNB' && tokenAllowance < tokenBalance)}
                onClick={swap}
              >
                Swap
              </Button>
              :
              <Button
                className="w-full anji-green"
                disabled={account && isRightNetwork(chainId)}
                onClick={toggleWalletModal}
              >
                {
                  (!account || !isRightNetwork(chainId)) ? 'Connect Wallet'
                    : !estimated ? 'Enter an amount'
                      : checkBalances()
                }
              </Button>
          }
        </div>
        {
          settingsOpen &&
          <>
            <div
              className="fixed left-0 top-0 w-full h-full"
              onClick={() => toggleSettings(false)}
            >

            </div>
            <Settings
              onClick={(e) => e.stopPropagation()}
            >
              <div className="px-3 pt-4 text-white text-left">
                Transaction Settings
              </div>
              <Divider />
              <div className="p-3 text-white flex items-center justify-start flex-col">
                <div className="w-full flex items-center justify-start flex-col mb-3">
                  <span className="w-full text-left text-gray-400">Slippage tolerance</span>
                  <div className="w-full flex items-center justify-between mt-1">
                    <Button
                      activeBg="linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), #4E555D"
                      radius="20px"
                      className={`
                          px-3 py-1 anji-green
                          ${slippage < 0 ? '' : 'active'}
                        `}
                      padding="0.25rem 0.75rem"
                      onClick={() => setSlipPage(-1)}
                    >
                      Auto
                    </Button>
                    <div>
                      <SettingInput
                        placeholder="1"
                        width="100px"
                        className={`mx-3 ${slippage < 0 ? '' : 'active'}`}
                        value={slippage < 0 ? defaultSlippage : slippage}
                        onChange={(e) => checkAndUpdate(e.target.value, setSlipPage)}
                        onFocus={() => {
                          if (slippage < 0)
                            setSlipPage(defaultSlippage)
                        }}
                      />
                      %
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-start flex-col mb-3">
                  <span className="w-full text-left text-gray-400">Transaction deadline</span>
                  <div className="w-full flex items-center justify-start mt-1">
                    <SettingInput
                      placeholder="30"
                      width="59px"
                      className={`mx-3 ${deadline !== prevDeadline ? 'active' : ''}`}
                      value={deadline}
                      onChange={(e) => checkAndUpdate(e.target.value, setDeadline)}
                    />
                    minutes
                  </div>
                </div>
              </div>
            </Settings>
          </>
        }
      </SwapContainer>
      {
        account && isRightNetwork(chainId) && tokenA && tokenA.symbol !== 'BNB' && tokenAllowance < tokenBalance ?
          <div className="mt-5 text-white w-full">
            <div style={{ fontSize: 11 }}>
              Approve your wallet to allow AnjiSwap to swap.
            </div>
            <div
              className="anji-green rounded-3xl py-3 mt-3 cursor-pointer btn"
              onClick={status === STATUS.READY ? approveToken : null}
            >
              Approve {tokenA.symbol}
              {
                status === STATUS.PENDING &&
                <div
                  className="absolute w-full h-full left-0 top-0 rounded-full"
                  style={{
                    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
                  }}
                />
              }
            </div>
          </div>
          :
          null
      }
      {
        checkBalances() === 'Insufficient BNB balance' &&
        <SwapContainer
          className="w-full max-w-2xl mt-3 dark-box-shadow"
        >
          <div
            className="px-3 pt-3 text-white flex items-center justify-between"
          >
            <span>Buy BNB for your wallet</span>
          </div>
          <Divider />
          <div
            className="p-3 text-white text-justify"
          >
            Easily and securely buy BNB with your preferred payment method. BNB is required to buy ANJI tokens.
            <Button
              className="w-full mt-3 anji-green btn"
              onClick={() => window.open('https://app.transak.com/')}
            >
              Buy through Transak
            </Button>
          </div>
        </SwapContainer>
      }
    </>
  )
}
