import React, { useEffect, useState } from 'react'
import { TokenKeys, setToken } from '../state/swap/actions'
import { useModalOpen, useSelectTokenModalToggle } from '../state/application/hooks'

import { ApplicationModal } from '../state/application/actions'
import Button from '../components/Button'
import { ETHER } from '@pancakeswap/sdk'
import { FaRegTimesCircle } from 'react-icons/fa'
import Modal from '../components/Modal'
import { Tokens } from '../constants/tokens'
import { Tokens as TokensCls } from '../constants/Token'
import { isAddress } from '../utils'
import { isRightNetwork } from '../utils/web3'
import { sortByName } from '../utils/sort'
import styled from 'styled-components'
import { useCurrentKey } from '../state/swap/hooks'
import { useDispatch } from 'react-redux'
import { useGetToken } from '../state/swap/hooks'
import { useToken } from '../hooks/Tokens'
import { useWeb3React } from '@web3-react/core'

const Option = styled.div`
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), #4E555D;
  background-blend-mode: soft-light, normal;
  box-shadow: inset 2.5px 2.5px 5px #35373E;
  border-radius: 20px;
  border: 2px solid rgba(144, 239, 255, 0.1);
  &:hover {
    border: 2px solid rgba(144, 239, 255, 0.5);
  }

  display: flex;
  align-items: center;
  padding: 0.25rem 0.55em;
  margin: 5px;
  min-width: 90px;

  cursor: pointer;
`

const CustomInput = styled.input`
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), rgb(78, 85, 93);
    background-blend-mode: soft-light, normal;
    box-shadow: rgb(53 55 62) 2.5px 2.5px 5px inset;
    border-radius: 20px;
    border: 2px solid rgb(83 101 104);
    color: white;

    &:hover:not(:disabled) {
        border: 2px solid rgba(144, 239, 255, 0.5);
    }
`

const ClearText = styled.div`
    div {
        opacity: 0.6;
    }

    div:hover  {
        opacity: 1;
    }
`


const PurpleText = styled.div`
  color: rgb(40, 13, 95);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
`

const WhiteText = styled.div`
  color: white;
  font-size: ${({ size }) => size}px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
`

const ScanLink = styled.a`
  color: #2aaf93;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`

export default function SelectTokenModal() {
  const { chainId, account } = useWeb3React()
  const [tokensFromStore, setTokensFromStore] = useState([])
  // const [selectedToken, setSelectToken] = useState()
  const [searchQuery, setSearchQuery] = useState('')
  const selectTokenModalOpen = useModalOpen(ApplicationModal.SELECT_TOKEN)
  const toggleSelectTokenModal = useSelectTokenModalToggle()
  // const allTokens = useAllTokens()
  const curKey = useCurrentKey()
  const tokenStake = useGetToken(TokenKeys.TOKEN_C)
  const tokenDeposit = useGetToken(TokenKeys.TOKEN_E)
  // const tokenComparator = useTokenComparator(false)
  const dispatch = useDispatch()
  const isStake = curKey?.indexOf('stake') > -1;
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  const [sureCheckModal, setSureCheckModal] = useState(false);
  const [understand, setUnderstand] = useState(false)
  const isReferral = curKey?.indexOf('referral') > -1;

  let itemData = isAddressSearch ? (searchToken ? [searchToken] : []) : [...Tokens.list.slice(0, Tokens.list.findIndex(x => x.symbol === 'BNB')), ...sortByName(tokensFromStore), ...Tokens.list.slice(Tokens.list.findIndex(x => x.symbol === 'BNB'))]
    .filter((token) => {
      if (curKey === TokenKeys.TOKEN_A || curKey === TokenKeys.TOKEN_B) {
        return !token.deprecated;
      } else if (curKey === TokenKeys.TOKEN_C) {
        return token.stake && !token.stake_disabled;
      } else if (curKey === TokenKeys.TOKEN_D) {
        if (tokenStake) {
          return Boolean(tokenStake.stake_contracts[token.symbol]);
        } else {
          return Tokens.list.some(_token => Boolean(_token.stake_contracts[token.symbol]));
        }
      } else if (curKey === TokenKeys.TOKEN_E) {
        return token.stake_deposits;
      } else if (curKey === TokenKeys.TOKEN_F) {
        if (tokenDeposit) {
          return Boolean(tokenDeposit.stake_contracts[token.symbol]);
        } else {
          return Tokens.list.some(_token => Boolean(_token.stake_contracts[token.symbol]));
        }
      }  else if (curKey === TokenKeys.TOKEN_G || curKey === TokenKeys.TOKEN_H) {
        return token.referral
      }
      return true;
    })

  const useLocalstorageChange = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
    else
      localStorage.setItem('customToken', '[]')
  }
  const handleFirstStore = () => {
    if (localStorage.getItem('customToken') !== null)
      setTokensFromStore(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
    else
      localStorage.setItem('customToken', '[]')
  }
  useEffect(() => {
    handleFirstStore()
    document.addEventListener(
      'storageChange',
      useLocalstorageChange,
      false
    )
    return () => document.removeEventListener('storageChange', useLocalstorageChange)
  }, [])

  const addDefaultSrc = (e) => {
    e.target.src = '/images/unknown-token.png'
  }

  const onSwapImportClose = () => {
      setUnderstand(false);
      setSureCheckModal(false);
      setSearchQuery();
  };

  return (
    <>
      <Modal
        title="Select a token"
        hasClose={true}
        onClose={toggleSelectTokenModal}
        show={selectTokenModalOpen}
      >
        <div className='overflow-auto flex flex-col'>
          {
            (!isStake && ! isReferral)?
              <div className='pl-3 pr-3 mb-3 rounded-md pb-5'>
                <CustomInput disabled={!account || !isRightNetwork(chainId)} type='text' className='w-full border-none outline-none rounded-3xl px-3 pt-1 pb-1' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Custom Token (Contract Address)" />
                {
                  !account || !isRightNetwork(chainId) ?
                    <div className="text-xs text-white opacity-60 text-right mt-1">Please connect your wallet before adding a custom token.</div>
                    :
                    <ClearText className="flex items-center justify-end pt-2 text-md text-white">
                      <div className="cursor-pointer flex items-center text-md text-white" onClick={() => setSearchQuery('')} >
                        <FaRegTimesCircle className="mr-1"/>
                        <span>Clear</span>
                      </div>
                    </ClearText>
                }
              </div>
              :
              null
          }
          <div className="grid grid-cols-2 md:grid-cols-5 sm:grid-cols-4 text-white">
            {
              itemData.map((each, i) =>
                <Option
                  key={i}
                  onClick={() => {
                    if (searchQuery) {
                        setSureCheckModal(true)
                    }
                    else {
                      dispatch(setToken(each))
                    }
                    toggleSelectTokenModal()
                  }}
                >
                  <img onError={addDefaultSrc} src={each === ETHER ? '/images/coins/bnb.png' : (each.logo ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${each.address}/logo.png` ?? '/images/unknown-token.png')} className="bg-white rounded-full" alt="" width={20} height={20} />
                  <span className="ml-1">{each.symbol}</span>
                </Option>
              )
            }
          </div>
        </div>
      </Modal>
      <Modal
            title="Custom swap token"
            hasClose={true}
            onClose={() => onSwapImportClose()}
            show={sureCheckModal}
        >
            <div className='flex p-4 rounded-md border-solid border-2 border-yellow-300' style={{ background: 'rgba(255, 247, 235)' }}>
                <div className='mr-3'>
                    <svg viewBox="0 0 24 24" fill="rgb(255, 178, 55)" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z"></path></svg>
                </div>
                <PurpleText>
                    Anyone can create a BEP20 token on BSC with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.<br /><br />
                    If you purchase an arbitrary token, you may be unable to sell it back.
                </PurpleText>
            </div>
            <div className='mt-3 flex p-1 items-center rounded-md border-solid border-2 border-pink-500'>
                <div className='mr-3'>
                    <svg viewBox="0 0 24 24" fill="rgb(255, 178, 55)" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z"></path></svg>
                </div>
                <WhiteText size={12}>
                    Unknown source
                </WhiteText>
            </div>
            <WhiteText className='mt-3' size={16}>
                {searchToken?.name} ({searchToken?.symbol})
            </WhiteText>
            <div className='flex justify-between items-center'>
                <WhiteText className='mt-3' size={16}>
                    {searchToken?.address?.length > 16 ? `${searchToken?.address?.slice(0, -8)}...` : searchToken?.address}
                </WhiteText>
                <ScanLink href={`https://bscscan.com/address/${searchToken?.address}`} target="_blank">
                    (View on BscScan)
                </ScanLink>
            </div>
            <div className='mt-3 flex justify-between items-center'>
                <WhiteText className='flex items-center' size={16}>
                    <div className='flex mr-2'>
                        <input type='checkbox' checked={understand} onChange={(e) => setUnderstand(!understand)} />
                    </div>
                    <div style={{ 'cursor': 'pointer' }} onClick={(e) => setUnderstand(!understand)}>
                        I understand
                    </div>
                </WhiteText>
                <Button
                    width='100px'
                    className='anji-green'
                    disabled={!understand}
                    onClick={() => {
                        dispatch(setToken(searchToken))
                        onSwapImportClose();
                    }}
                >
                    <div className='flex justify-center w-full'>Import</div>
                </Button>
            </div>

        </Modal>
    </>
  )
}
