import React, { useEffect, useState } from 'react'
import { formatBN, formatBalance, getContract, getDepositAllowance, isRightNetwork } from '../../utils/web3'
import { formatValue, toFloat } from '../../utils/number'
import { getStakingContractDetails, useStakingContract } from '../../hooks/useContract'

import BEP20_ABI from '../../assets/contracts/bep20_abi.json'
import Button from '../../components/Button'
import { IoLockClosedSharp } from 'react-icons/io5'
import { MdLockOpen } from 'react-icons/md'
import { TokenKeys } from '../../state/swap/actions'
import TokenSelect from '../../components/TokenSelect'
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useGetToken } from '../../state/swap/hooks'
import { useWeb3React } from '@web3-react/core'

const WidgetContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TokenAmount = styled.input`
  background: transparent;
  color: white;
  text-align: right;
  width: 200px;
  font-family: 'Roboto Mono', monospace;
  &:focus {
    outline: none;
  }
`
const TokenInfo = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  color: white;
`

export default function DepositStakePage() {
    const { chainId, account, library } = useWeb3React()
    const dispatch = useDispatch()

    const tokenStake = useGetToken(TokenKeys.TOKEN_E)
    const tokenReward = useGetToken(TokenKeys.TOKEN_F)
    const Staking_Contract = useStakingContract(tokenStake, tokenReward)
    const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)

    const STATUS = {
        READY: 0,
        PENDING: 1
    }
    const [status, setStatus] = useState(STATUS.READY)
    const [fetchIndex, setFetchIndex] = useState(0)
    const tryFetch = () => {
        setFetchIndex(fetchIndex + 1)
    }

    const [allowance, setStakeAllowance] = useState(0)
    const [rewardDistribution, setRewardDistribution] = useState('')
    const [totalWallets, setTotalWallets] = useState('')
    const [totalStakedTokens, setTotalStakedTokens] = useState('')
    const [totalUnclaimedRewards, setTotalUnclaimedRewards] = useState('')

    const inputRegex = new RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
    const checkAndUpdate = (amount, callback) => {
        const nextUserInput = amount ? amount.toString().replace(/[,]/g, '') : ''
        if (!nextUserInput || inputRegex.test(nextUserInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))) {
        if(callback)
            callback(nextUserInput.toString())
        }
    }

    const depositRewards = async () => {
        try {
            if (!Staking_Contract) return
            if (toFloat(rewardDistribution) === 0) return
            setStatus(STATUS.PENDING)
            let tx
            tx = await Staking_Contract.deposit(formatBN(rewardDistribution, tokenReward.decimals))
            tx = await tx.wait(1)
            dispatch(setTx(tx.transactionHash, `${toFloat(rewardDistribution)} ${tokenReward.symbol} Deposited`, true))
            setRewardDistribution('')
            tryFetch()
        } catch (e) {
            dispatch(setTx('', (e.data && e.data.message) || e.message, false))
        }
        setStatus(STATUS.READY)
    }

    useEffect(() => {
        async function fetchTokenAllowance() {
            try {
                let newAllowance = await getDepositAllowance(account, tokenStake, tokenReward, library)
                newAllowance = formatBalance(newAllowance, tokenReward.decimals)
                setStakeAllowance(toFloat(newAllowance))
            } catch(e) { console.log(e) }
        }

        async function totalStakedWallets() {
            try {
                if (!Staking_Contract) return
                if (!stakeContractDetails.info) return
                const total = await Staking_Contract.totalWalletsStaked()
                setTotalWallets(parseFloat(total.toString()))
            } catch(e) { console.log(e) }
        }

        async function totalStakedTokens() {
            try {
                if (!Staking_Contract) return
                if (!stakeContractDetails.info) return
                const total = await Staking_Contract.totalStakedTokens()
                setTotalStakedTokens(formatValue(formatBalance(total, tokenReward.decimals), '0.0'))
            } catch(e) { console.log(e) }
        }

        async function totalUnclaimedRewards() {
            try {
                if (!Staking_Contract) return
                if (!stakeContractDetails.info) return
                const total = await Staking_Contract.totalUnclaimedRewards()
                setTotalUnclaimedRewards(formatValue(formatBalance(total, tokenReward.decimals), '0.0'))
            } catch(e) { console.log(e) }
        }

        if(!library || !account || !isRightNetwork(chainId) || !tokenStake || !tokenReward) {
            setStakeAllowance(0)
            setRewardDistribution('')
            setTotalWallets(0)
            setTotalStakedTokens(0)
            setTotalUnclaimedRewards(0)
            return
        }

        fetchTokenAllowance()
        totalStakedWallets()
        totalStakedTokens()
        totalUnclaimedRewards()

    }, [chainId, account, library, tokenStake, tokenReward, Staking_Contract, stakeContractDetails, fetchIndex])

    const approveToken = async () => {
        try {
            if(!library || !account || !isRightNetwork(chainId) || !tokenReward) return
            const rewardTokenContract = getContract(tokenReward.address, BEP20_ABI, library, account)
            const stakeTokenContract = getContract(tokenStake.address, BEP20_ABI, library, account)
            const stakeContractDetails = getStakingContractDetails(tokenStake, tokenReward)
            if(!stakeTokenContract) return
            if(!stakeContractDetails.address) return
            setStatus(STATUS.PENDING)
            let tx = await rewardTokenContract.approve(stakeContractDetails.address, formatBN(rewardDistribution, tokenReward.decimals))
            tx = await tx.wait(1)
            dispatch(setTx(tx.transactionHash, `${tokenReward.symbol} Deposite Approved ${rewardDistribution}`, true))

            tryFetch()
        } catch(e) {
            dispatch(setTx('', (e.data && e.data.message) || e.message, false))
        }
        setStatus(STATUS.READY)
    }

    return (
        <>
            <WidgetContainer className="w-full max-w-2xl dark-box-shadow">
                <div className="px-3 pt-3 text-white flex items-center justify-between">
                    <span>Deposit Staking Rewards</span>
                    {
                        account && isRightNetwork(chainId) ?
                        <MdLockOpen width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
                        :
                        <IoLockClosedSharp width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
                    }
                </div>
                <Divider />
                <TokenArea>
                    <TokenSelect tokenKey={TokenKeys.TOKEN_E} title="Stake" />
                </TokenArea>
                <Divider />
                <TokenArea>
                    <TokenSelect tokenKey={TokenKeys.TOKEN_F} title="Reward" />
                    <TokenAmount
                        placeholder="0.0"
                        disabled={ !account || !isRightNetwork(chainId) }
                        onChange={ (e) => checkAndUpdate(e.target.value, setRewardDistribution) }
                        value={ formatValue(rewardDistribution) }
                    />
                </TokenArea>
                <div className="p-3 flex items-center justify-between flex-col">
                    {
                        account && isRightNetwork(chainId) ?
                        <Button
                            className="w-full anji-colorful"
                            disabled={status === STATUS.PENDING || !tokenReward}
                            onClick={depositRewards}
                        >
                            Distribute Rewards
                        </Button>
                        :
                        null
                    }
                </div>
                {
                account && isRightNetwork(chainId) && tokenStake && tokenReward && stakeContractDetails && stakeContractDetails.info ?
                    <div className="mt-5">
                        <Divider />
                        <div className="px-3 pt-3 text-white flex items-center justify-between">
                            <span>Stake Information</span>
                        </div>
                        <TokenInfo className="px-3 flex flex-row items-center justify-between">
                            <span className="number-font">Total Wallets Staked</span>
                            <span className="number-font opacity-60">{ totalWallets }</span>
                        </TokenInfo>
                        <TokenInfo className="px-3 flex flex-row items-center justify-between">
                            <span className="number-font">Total { tokenStake.symbol } Staked</span>
                            <span className="number-font opacity-60">{ totalStakedTokens }</span>
                        </TokenInfo>
                        <TokenInfo className="px-3 flex flex-row items-center justify-between pb-3">
                            <span className="number-font">Total { tokenReward.symbol } Rewards Unclaimed</span>
                            <span className="number-font opacity-60">{ totalUnclaimedRewards }</span>
                        </TokenInfo>
                    </div>
                    :
                    null
                }
            </WidgetContainer>
            {
                account && isRightNetwork(chainId) && allowance < toFloat(rewardDistribution) ?
                <div className="mt-5 text-white w-full">
                    <div style={{ fontSize: 11 }}>
                        Approve your wallet to allow AnjiSwap to stake.
                    </div>
                    <div className="anji-green rounded-3xl py-3 mt-3 cursor-pointer btn" onClick={status === STATUS.READY ? approveToken : null}>
                    Approve
                    {
                        status === STATUS.PENDING &&
                        <div
                            className="absolute w-full h-full left-0 top-0 rounded-full"
                            style={{
                                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
                            }}
                        />
                    }
                    </div>
                </div>
                :
                null
            }
        </>
    )
}
