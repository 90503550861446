import { formatValue, noExponents, toDecimalPrecision } from '../utils/number';

import STAKE_ABI from '../assets/contracts/timelock_stake_abi.json';
import TIME_LOCK_STAKE_ABI from '../assets/contracts/timelock_stake_abi.json';
import { TokenInfo } from '@uniswap/token-lists';

export interface TokenInterface {
    address: string;
    decimals: number;
    logo: string;
    name: string;
    slippage: number;
    symbol: string;
    colour: string;
    description: string;
    socials: { type: string, link: string }[];
    tokenomics: { tax: number, label: string }[];

    chainId?: number;
    rewards?: boolean;
    sell_tokenomics?: { tax: number, label: string }[];
    amount?: string;
    pair_address?: string;
    pcs?: number;
    sell_slip?: number;
    sell_pcs?: boolean;
    stake_tokens?: StakeTokenInterface[];
    stake_deposits?: boolean;
    deprecated?: boolean;
}

export interface StakeTokenInterface {
    symbol: string;
    address: string;
    abi?: any;
    amount?: number;
    time_lock?: number;
    legacy_stake?: boolean;
    deprecated?: boolean;
    info?: boolean;
}

export class StakeToken implements StakeTokenInterface {
    public symbol: string;
    public address: string;
    public abi: any;
    public amount: number = 50;
    public time_lock: number = 0;
    public legacy_stake: boolean = false;
    public deprecated: boolean = false;
    public info: boolean = true;

    constructor(data: StakeTokenInterface) {
        Object.assign(this, data);

        if (this.abi) {
            return;
        }

        if (this.time_lock) {
            this.abi = TIME_LOCK_STAKE_ABI;
        } else {
            this.abi = STAKE_ABI;
        }
    }
}

export class Token implements TokenInterface {
    public address: string;
    public amount: string = '';
    public chainId: number = 56;
    public colour: string;
    public decimals: number;
    public deprecated: boolean = false;
    public description: string;
    public logo: string;
    public name: string;
    public pcs: number = 2;
    public stake_deposits: boolean;
    public stake_disabled: boolean = false;
    public slippage: number;
    public symbol: string;
    public socials: { type: string, link: string }[];
    public tokenomics: { tax: number, label: string }[];
    public rewards = false;
    public referral: boolean = false;

    public sell_tokenomics?: { tax: number, label: string }[];
    public pair_address?: string;
    public sell_slip?: number;
    public sell_pcs?: boolean = false;
    public stake_contracts: {
        [key: string]: StakeToken
    } = {};
    public stake_tokens: StakeTokenInterface[] = [];

    constructor(data: TokenInterface) {
        Object.assign(this, data);

        if (this.stake_tokens && this.stake_tokens.length > 0) {
            this.stake_tokens.forEach(params => {
                this.stake_contracts[params.symbol] = new StakeToken(params);
            });

        }

        this.stake_deposits = data.stake_deposits ?? false;
    }

    public get bnbSymbol(): string {
        return 'BNB';
    }

    public get busdSybmol(): string {
        return 'BUSD';
    }

    public get ecoToken(): boolean {
        return ['ANJI', 'BMBO'].includes(this.symbol.toUpperCase());
    }

    public get buy_tax(): number {
        return this.tokenomics?.map(tokenomic => tokenomic.tax).reduce((a, b) => a + b) || 0;
    }

    public get is_bnb(): boolean {
        return this.symbol === this.bnbSymbol;
    }

    public get is_busd(): boolean {
        return this.symbol === this.busdSybmol;
    }

    public get sell_slippage(): number {
        return this.sell_slip || this.slippage;
    }

    public get sell_tax(): number {
        return this.sell_tokenomics?.map(tokenomic => tokenomic.tax).reduce((a, b) => a + b) || 0;
    }

    public get stake(): boolean {
        return this.stake_tokens.length > 0;
    }

    public calculatePrice(balances: { [key: string]: number }, prices: { [key: string]: number }) {
        if (this.is_bnb) {
            return balances[this.symbol] * prices[this.symbol];
        }

        if (this.is_busd) {
            return balances[this.symbol] * 1;
        }
        return balances[this.symbol] * prices[this.symbol] * prices[this.bnbSymbol];
    }

    public usdPrice(prices: { [key: string]: number }): string {
        const price = (this.is_bnb) ? prices[this.symbol] : prices[this.bnbSymbol] * prices[this.symbol],
            decimalPrecision = toDecimalPrecision(price, 4);

        return formatValue(noExponents(decimalPrecision));
    }
}

export class Tokens {
    private _tokens: Token[] = [];

    public get bnb(): Token | undefined {
        return this._tokens.find(token => token.is_bnb);
    }

    public get bsud(): Token | undefined {
        return this._tokens.find(token => token.is_busd);
    }

    public get coming_soon(): Token[] {
        return this._tokens.filter(_token => !_token.address);
    }

    public get tradable(): Token[] {
        return this._tokens.filter(_token => _token.address && _token.pair_address && !_token.deprecated);
    }

    public get list(): Token[] {
        return this._tokens.filter(_token => _token.address);
    }

    public get all(): Token[] {
        return this._tokens;
    }

    public customToken(address: string): boolean {
        return !this._tokens.some(_token => _token.address === address);
    }

    public customToToken(params: TokenInfo): Token {
        return new Token({
            name: params.name,
            address: params.address,
            decimals: params.decimals,
            logo: params.logoURI || '',
            symbol: params.symbol,

            // Default info
            slippage: 11,
            colour: '',
            description: '',
            socials: [],
            tokenomics: [],
        });
    }

    constructor(_tokens: TokenInterface[], chainId: number = 56) {
        const uniqueTokens = [...new Map(_tokens.map(item => [item['address'], item])).values()];

        this._tokens = uniqueTokens.map(token => new Token(token)).filter(_token => _token.chainId === chainId);
    }

    public getToken(symbol: string): Token | undefined {
        return this._tokens.find(token => token.symbol === symbol);
    }
}
