import React, { useEffect, useState } from 'react'
import { TokenKeys, setCurrentToken, setToken } from '../../state/swap/actions'
import { formatBN, isRightNetwork } from '../../utils/web3'
import { formatValue, toFloat } from '../../utils/number'
import { useHistory, useLocation } from 'react-router-dom';

import Button from '../../components/Button'
import { ROUTERS } from '../../constants/contracts';
import TokenSelect from '../../components/TokenSelect'
import { Tokens } from '../../constants/tokens';
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components';
import { useContract } from '../../hooks/useContract';
import { useDispatch } from 'react-redux'
import { useGetToken } from '../../state/swap/hooks';
import { useWalletModalToggle } from '../../state/application/hooks'
import { useWeb3React } from '@web3-react/core'

const ReferralContainer = styled.div`
background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
background-blend-mode: soft-light, normal;
border-radius: 15px;
position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


export default function ReferralPage() {
  const { chainId, account } = useWeb3React();
  const token = useGetToken(TokenKeys.TOKEN_H);
  const STATUS = {
    READY: 0,
    PENDING: 1
  }
  const [status, setStatus] = useState(STATUS.READY)
  const [referralInformation, setReferralInformation] = useState({
    referral_percent: 0,
    required_bnb: 0,
    contract_bnb: 0,
    token_name: '',
  })

  let tokenCls = undefined;
  if(token) {
    tokenCls = Tokens.customToToken(token);
  }

  const dispatch = useDispatch();
  const history = useHistory()
  const search = useLocation().search;
  const toggleWalletModal = useWalletModalToggle()

  const anjiReferral = useContract(ROUTERS.anji_referral.address, ROUTERS.anji_referral.abi, true);
  const depositReward = async () => {
    try {
      let tx = null
        setStatus(STATUS.PENDING)
        tx = await anjiReferral.depositExternalBNB(token.address, {value: formatBN(referralInformation.required_bnb)});
      if(tx) {
        tx = await tx.wait(1)
        dispatch(setTx(tx.transactionHash, `Deposited`, true))
        let referral_percent = (toFloat((await anjiReferral.tokenPercentlist(token.address))))/10;
        let required_bnb = toFloat(await anjiReferral.perTokenDepositRequired(token.address))/(10 ** Tokens.getToken('BNB').decimals)
        let contract_bnb = toFloat((await anjiReferral.depositedPerToken(token.address)))/(10 ** Tokens.getToken('BNB').decimals)
        setReferralInformation({
          referral_percent: referral_percent,
          required_bnb: required_bnb,
          contract_bnb: contract_bnb,
          token_name: token.name,
        })
      }
    } catch(e) {

      dispatch(setTx('', (e.data && e.data.message) || e.message, false))
    }
    setStatus(STATUS.READY)
  }

  useEffect( () => {
    getReferralInfo()
  }, [dispatch, search, chainId, account])

  useEffect(() => {
    if (tokenCls) {
      const referralParam = new URLSearchParams(search).get('token') || ''
      if(referralParam.toLowerCase() !== tokenCls.symbol.toLowerCase()) {
          history.replace({ search: new URLSearchParams({ token: tokenCls.symbol }).toString() })
      }
    }
  }, [tokenCls])

  async function getReferralInfo () {
    const referralParam = new URLSearchParams(search).get('token') || '';
    const token = Tokens.getToken(referralParam.toUpperCase());

    if  (token && isRightNetwork(chainId))  {
      let referral_percent = toFloat((await anjiReferral.tokenPercentlist(token.address)))/10;
      let required_bnb = toFloat(await anjiReferral.perTokenDepositRequired(token.address))/(10 ** Tokens.getToken('BNB').decimals)
      let contract_bnb = toFloat((await anjiReferral.depositedPerToken(token.address)))/(10 ** Tokens.getToken('BNB').decimals)
      console.log(formatValue(await anjiReferral.perTokenDepositRequired(token.address)));
      setReferralInformation({
        referral_percent: referral_percent,
        required_bnb: required_bnb,
        contract_bnb: contract_bnb,
        token_name: token.name,
      })
      dispatch(setCurrentToken(TokenKeys.TOKEN_H))
      dispatch(setToken(token))
    } else {
      setReferralInformation({
        referral_percent: 0,
        required_bnb: 0,
        contract_bnb: 0,
        token_name: '',
      });
    }
  }

  return (
    <ReferralContainer className="w-full max-w-2xl dark-box-shadow">
      <div
        className="px-3 pt-3 text-white flex items-center justify-between"
      >
        <span>Referral Rewards</span>
      </div>
      <Divider />
      <TokenArea>
        <TokenSelect tokenKey={TokenKeys.TOKEN_H} title="Referral"/>
          <div
            className="px-3 pt-3 text-white flex items-center justify-left"
          >
          </div>
      </TokenArea>
      <div
        className="px-3 pt-3 text-white flex items-center justify-between"
      >
        <span>Referral Information</span>
      </div>
        <div className="flex justify-between px-3 w-full text-white text-sm number-font" style={{fontSize: 11}}>
            <span>{ referralInformation.token_name } Referral %</span>
            <span>{ (referralInformation.referral_percent).toString() }%</span>
        </div>
        <div className="flex w-full justify-between px-3 text-white text-sm number-font" style={{fontSize: 11}}>
            <span>Token BNB required to be deposited</span>
            <span>{ referralInformation.required_bnb }</span>
        </div>
        <div className="flex justify-between px-3 text-white text-sm number-font" style={{fontSize: 11}}>
            <span>Token BNB in Contract</span>
            <span>{ referralInformation.contract_bnb }</span>
        </div>
      <div
          className="p-3 flex items-center justify-between flex-col"
      >
        {
          account && isRightNetwork(chainId) ?
            <Button
              className="w-full anji-colorful"
              disabled={status === STATUS.PENDING || parseFloat(referralInformation.required_bnb) <= 0 || referralInformation.required_bnb === '' }
              onClick={depositReward}
            >
              Deposit Referral Rewards
            </Button>
          :
            <Button
              className="w-full anji-green"
              disabled={account && isRightNetwork(chainId)}
              onClick={toggleWalletModal}
            >
              {
                (!account || !isRightNetwork(chainId)) ? 'Connect Wallet':''
              }
            </Button>
          }
      </div>
    </ReferralContainer>
  )
}
