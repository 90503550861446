import React from 'react'
import styled from 'styled-components'

const WalletContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
  margin: 10px 0;
  cursor: pointer;
`

const ComingSoonBadge = styled.span`
    padding: 2px 15px;
    border-radius: 1000px;
`

export default function ComingSoonPanel({ icon, title, info, symbol }) {
    const addDefaultSrc = (e) => {
        e.target.src = '/images/unknown-token.png'
    }
    return (
        <WalletContainer onClick={() => info()} className="w-full max-w-2xl dark-box-shadow flex flex-row items-center justify-content text-white p-3">
            <img onError={addDefaultSrc} src={symbol === 'BNB' ? '/images/coins/bnb.png' : (icon ?? '/images/unknown-token.png')} width={48} height={48} alt="" className="mr-3" />
            <div className="flex flex-col flex-grow items-center justify-start text-left">
                <span className="w-full">{title}</span>
            </div>
            <div className="flex flex-col items-center justify-end text-right">
                <ComingSoonBadge className="w-full anji-colorful">Coming Soon</ComingSoonBadge>
            </div>
        </WalletContainer>
    )
}
