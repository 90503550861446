import React, { useEffect, useState } from 'react'
import { useModalOpen, useRemoveCustomTokenModal } from '../state/application/hooks'

import { ApplicationModal } from '../state/application/actions'
import Button from '../components/Button'
import { ETHER } from '@pancakeswap/sdk'
import { FaRegTimesCircle } from 'react-icons/fa'
import Modal from '../components/Modal'
import { Tokens as TokensCls } from '../constants/Token'
import _ from 'lodash'
import { sortByName } from '../utils/sort'
import styled from 'styled-components'

const Option = styled.div`
  flex: 1;
  background: linear-gradient(134.85deg, rgba(0, 0, 0, 0.4) -9.62%, rgba(255, 255, 255, 0.4) 136.92%), #4E555D;
  background-blend-mode: soft-light, normal;
  box-shadow: inset 2.5px 2.5px 5px #35373E;
  border-radius: 20px;
  border: 2px solid rgba(144, 239, 255, 0.1);
  &:hover {
    border: 2px solid rgba(144, 239, 255, 0.5);
  }
  display: flex;
  align-items: center;
  padding: 0.25rem 0.55em;
  margin: 5px;
  min-width: 90px;
  cursor: pointer;
`

const ClearText = styled.div`
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
`

export default function RemoveTokenModal() {
  const [tokensFromStore, setTokensFromStore] = useState([])
  const removeTokenModalOpen = useModalOpen(ApplicationModal.REMOVE_TOKEN_FOR_WALLET)
  const toggleRemoveTokenModal = useRemoveCustomTokenModal()

  const handleFirstStore = () => {
    if (localStorage.getItem('customToken') !== null) {
      console.log(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
      setTokensFromStore(new TokensCls(JSON.parse(localStorage.getItem('customToken')))._tokens)
    }
    else
      localStorage.setItem('customToken', '[]')
  }
  useEffect(() => {
    if(removeTokenModalOpen) handleFirstStore()
  }, [removeTokenModalOpen])

  const addDefaultSrc = (e) => {
    e.target.src = '/images/unknown-token.png'
  }

  const handleRemoveFromStore = (address) => {
    let tmp = [...tokensFromStore]
    tmp.splice(_.findIndex(tmp, x => x.address === address), _.filter(tmp, x => x.address === address).length)
    setTokensFromStore(tmp)
    localStorage.setItem('customToken', JSON.stringify(tmp))
  }

  return (
    <>
      <Modal
        title="Remove a token"
        hasClose={true}
        onClose={toggleRemoveTokenModal}
        show={removeTokenModalOpen}
      >
        <div className='max-h-96 overflow-auto flex flex-col'>
          <div className="grid grid-cols-1 sm:grid-cols-2 text-white">
            {
              sortByName(tokensFromStore).map((each, i) =>
                <div className='flex justify-between' key={i}>
                  <Option key={i}>
                    <img onError={addDefaultSrc} src={each === ETHER ? '/images/coins/bnb.png' : (each.logo ?? `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${each.address}/logo.png` ?? '/images/unknown-token.png')} className="bg-white rounded-full" alt="" width={20} height={20} />
                    <span className="ml-1">{each.symbol}</span>
                  </Option>
                  <ClearText className="flex items-center justify-end text-md ml-3 text-white">
                    <div className="cursor-pointer flex items-center text-md text-white" onClick={() => handleRemoveFromStore(each.address)}>
                      <FaRegTimesCircle className="mr-1" />
                      <span>Remove</span>
                    </div>
                  </ClearText>
                </div>
              )
            }
          </div>
          {(!tokensFromStore || tokensFromStore.length === 0) &&
            <div className='text-white mb-4'>No custom tokens currently added</div>
          }
        </div>
        <div className='flex justify-end'>
          <Button
            className="anji-green"
            width="170px"
            height="40px"
            onClick={() => toggleRemoveTokenModal()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}
