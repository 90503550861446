import React, { useEffect, useState } from 'react'
import { formatBalance, getContract, getStaked, isRightNetwork } from '../../utils/web3'
import { formatValue, toFloat } from '../../utils/number'

import BEP20_ABI from '../../assets/contracts/bep20_abi.json'
import Button from '../../components/Button'
import { IoLockClosedSharp } from 'react-icons/io5'
import { MdLockOpen } from 'react-icons/md'
import TokenSelect from '../../components/TokenSelect'
import { Tokens } from '../../constants/tokens'
import { UPGRADE_CONTRACT } from '../../constants/contracts'
import { setTx } from '../../state/tx/actions'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

const WidgetContainer = styled.div`
  background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.3) -22.55%, rgba(255, 255, 255, 0.3) 131.34%), #4E555D;
  background-blend-mode: soft-light, normal;
  border-radius: 15px;
  position: relative;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #FFFFFF44;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenArea = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TokenAmount = styled.input`
  background: transparent;
  color: white;
  text-align: right;
  width: 200px;
  font-family: 'Roboto Mono', monospace;
  &:focus {
    outline: none;
  }
`

export default function UpgradeSPNDPage() {
    const { chainId, account, library } = useWeb3React()
    const dispatch = useDispatch()

    const upgradeContract = getContract(UPGRADE_CONTRACT.address, UPGRADE_CONTRACT.abi, library, account)

    const oldToken = Tokens.getToken('SPND')
    const newToken = Tokens.getToken('ANJI')

    const oldTokenStake = Tokens.getToken('BMBO')

    const STATUS = {
        READY: 0,
        PENDING: 1
    }
    const [status, setStatus] = useState(STATUS.READY)
    const [fetchIndex, setFetchIndex] = useState(0)
    const tryFetch = () => {
        setFetchIndex(fetchIndex + 1)
    }

    const [allowance, setStakeAllowance] = useState(0)
    const [upgradeSpndAmount, setUpgradeSpndAmount] = useState('')
    const [entitlmentAmount, setEntitlmentAmount] = useState('')
    const [userDeposited, setUserDeposited] = useState(false)
    const [userClaimed, setUserClaimed] = useState(false)
    const [userUpgradeClaimAmount, setUserUpgradeClaimAmount] = useState(0)
    const [claimLive, setClaimLive] = useState(false)
    const [inSnapshot, setInSnapshot] = useState(false)
    const [hasStaked, setHasStaked] = useState(false);
    const [accountSnapshot, setAccountSnapshot] = useState('');

    useEffect(() => {
        async function setup() {
            await getSnapshot();

            fetchTokenAllowance()
            fetchClaimLive()
            fetchUpgradeAmount()
            fetchEntitlement()
            fetchUserDeposited()
            fetchUserClaimed()
            fetchInSnapshot()
            fetchHasStaked()
            fetchUserUpgradeClaimAmount()
        }

        async function getSnapshot() {
            if (accountSnapshot) {
                return accountSnapshot;
            }

            const req = await fetch(`https://nebulae42.com/wallet.php?wallet=${account}`);
            const json = await req.json();
            const snap = json.amount || '0';
            setAccountSnapshot(snap);
            return snap;
        }

        async function fetchHasStaked() {
            try {
                let stakedAmount = await getStaked(account, oldToken, oldTokenStake, library)
                formatBalance(stakedAmount, oldToken.decimals)
                if (toFloat(stakedAmount) > 0) {
                    setHasStaked(true);
                } else {
                    setHasStaked(false);
                }
            } catch (e) { console.log(e) }
        }

        async function fetchInSnapshot() {
            if (!upgradeContract) return
            let upgraded = await upgradeContract.walletUpgraded()

            if (upgraded) {
                setInSnapshot(true);
                return
            }

            const snap = await getSnapshot();

            setInSnapshot(Boolean(snap !== '0'))
        }

        async function fetchTokenAllowance() {
            try {
                const contract = getContract(oldToken.address, BEP20_ABI, library)
                const allowance = await contract.allowance(account, upgradeContract.address)
                const newAllowance = formatBalance(allowance, oldToken.decimals);
                setStakeAllowance(toFloat(newAllowance))
            } catch(e) { console.log(e) }
        }

        async function fetchClaimLive() {
            try {
                if (!upgradeContract) return
                const claimLive = await upgradeContract.claim_live()
                setClaimLive(claimLive)
            } catch(e) { console.log(e) }
        }

        async function fetchUserDeposited() {
            if (!upgradeContract) return
            let upgraded = await upgradeContract.walletUpgraded()
            setUserDeposited(upgraded)
        }

        async function fetchUpgradeAmount() {
            let amount = '0';

            if (!upgradeContract) return
            let deposited = await upgradeContract.walletUpgraded()

            if (deposited) {
                amount = await upgradeContract.walletUpgradeDeposited()
                amount = formatBalance(amount, oldToken.decimals)
                setUpgradeSpndAmount(toFloat(amount))
                return
            }

            const snapshotFetch = await getSnapshot();

            if (snapshotFetch === '0') {
                setUpgradeSpndAmount(toFloat(amount))
                return
            }

            let holding = await upgradeContract.userOldBalance()
            holding = formatBalance(holding, oldToken.decimals)
            holding = toFloat(holding)

            let snap = formatBalance(snapshotFetch, oldToken.decimals)
            snap = toFloat(snap)

            amount = snap > holding ? holding : snap;

            setUpgradeSpndAmount(amount)
        }

        async function fetchEntitlement() {
            let amount = '0';

            try {
                if (!upgradeContract) return
                let entitlement = await upgradeContract.myEntitlement()

                entitlement = formatBalance(entitlement, oldToken.decimals);

                if (toFloat(entitlement) > 0) {
                    amount = entitlement
                    setEntitlmentAmount(toFloat(amount))
                    return
                }

                const snapshotFetch = await getSnapshot();

                if (snapshotFetch === '0') {
                    setEntitlmentAmount(toFloat(amount))
                    return
                }

                let holding = await upgradeContract.userOldBalance()

                let check = toFloat(formatBalance(snapshotFetch, oldToken.decimals)) > toFloat(formatBalance(holding, oldToken.decimals)) ? holding : snapshotFetch;

                // console.log(check)

                amount = await upgradeContract.entitlementCalc(check)
                amount = formatBalance(amount, newToken.decimals)
            } catch(e) { console.log(e)}
            setEntitlmentAmount(toFloat(amount))
        }

        async function fetchUserClaimed() {
            const userClaimed = await upgradeContract.walletClaimed()

            setUserClaimed(userClaimed)
        }

        async function fetchUserUpgradeClaimAmount() {
            let amount = '0'
            try {
                if (!upgradeContract) return
                let claimed = await upgradeContract.myWalletTokenClaimed()
                amount = formatBalance(claimed, newToken.decimals)
            } catch (e) { console.log(e) }
            setUserUpgradeClaimAmount(toFloat(amount))
        }

        if(!library || !account || !isRightNetwork(chainId) || !oldToken || !newToken) {
            setStakeAllowance(0)
            setUpgradeSpndAmount(0)
            setEntitlmentAmount(0)
            setClaimLive(false)
            setUserDeposited(false)
            setUserClaimed(false)
            setInSnapshot(false)
            setHasStaked(false)
            setUserUpgradeClaimAmount(0)
            setAccountSnapshot('')
            return
        }

        setup();

    }, [chainId, account, library, oldToken, newToken, oldTokenStake, upgradeContract, fetchIndex, accountSnapshot])

    const approveToken = async () => {
        try {
            if(!library || !account || !isRightNetwork(chainId)) return
            const oldTokenContract = getContract(oldToken.address, BEP20_ABI, library, account)
            if (!upgradeContract) return
            setStatus(STATUS.PENDING)
            let tx = await oldTokenContract.approve(upgradeContract.address, '1000000000000000000000000')
            tx = await tx.wait(1)
            dispatch(setTx(tx.transactionHash, `${oldToken.symbol} Upgrade Approved`, true))
            tryFetch()
        } catch(e) {
            dispatch(setTx('', (e.data && e.data.message) || e.message, false))
        }
        setStatus(STATUS.READY)
    }

    const depositOldToken = async () => {
        try {
            if(!library || !account || !isRightNetwork(chainId) || !accountSnapshot) return
            if (!upgradeContract) return
            setStatus(STATUS.PENDING)

            const braavos = await upgradeContract.getBravos()

            const claimLive = await upgradeContract.claim_live();

            let tx = await upgradeContract.upgrade(accountSnapshot, braavos)
            tx = await tx.wait(1)

            let message = `${oldToken.symbol} Deposited to Upgrade`;

            if (claimLive) {
                message = `${oldToken.symbol} upgraded to ${newToken.symbol}`;
            }

            dispatch(setTx(tx.transactionHash, message, true))
            tryFetch()
        } catch (e) {
            dispatch(setTx('', (e.data && e.data.message) || e.message, false))
        }
        setStatus(STATUS.READY)
    }

    const claimUpgrade = async () => {
        try {
            if(!library || !account || !isRightNetwork(chainId)) return
            if (!upgradeContract) return
            setStatus(STATUS.PENDING)

            const claimLive = await upgradeContract.claim_live();

            if (!claimLive) return

            const userUpgraded = await upgradeContract.walletUpgraded()

            if (!userUpgraded) return

            const userClaimed = await upgradeContract.walletClaimed()

            if (userClaimed) return

            let tx = await upgradeContract.claim()
            tx = await tx.wait(1)
            dispatch(setTx(tx.transactionHash, `${newToken.symbol} Claimed from Upgrade`, true))
            tryFetch()
        } catch (e) {
            dispatch(setTx('', (e.data && e.data.message) || e.message, false))
        }
        setStatus(STATUS.READY)
    }

    return (
        <>
            <WidgetContainer className="w-full max-w-2xl dark-box-shadow">
                <div className="px-3 pt-3 text-white flex items-center justify-between">
                    <span>Upgrade</span>
                    {
                        account && isRightNetwork(chainId) ?
                        <MdLockOpen width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
                        :
                        <IoLockClosedSharp width={20} height={20} className="cursor-pointer" style={{opacity: 0.6}} />
                    }
                </div>
                <Divider />
                <TokenArea>
                    <TokenSelect _token={oldToken} title="Stake" readOnly="true" />
                    {
                        !inSnapshot ?
                            account && isRightNetwork(chainId) ?
                                <TokenAmount placeholder="Unable to upgrade" disabled={true} />
                                :
                                null
                            :
                            userDeposited ?
                                userClaimed ?
                                    <TokenAmount placeholder="SPND already upgraded" value="" disabled={true} />
                                    :
                                    <TokenAmount
                                        placeholder={ formatValue(upgradeSpndAmount) }
                                        disabled={true}
                                    >
                                    </TokenAmount>
                                :
                                <>
                                    <TokenAmount
                                        placeholder=""
                                        disabled={true}
                                        value={ formatValue(upgradeSpndAmount) }
                                    >
                                    </TokenAmount>
                                </>
                    }
                </TokenArea>
                {
                    account && isRightNetwork(chainId) && inSnapshot && !userDeposited ?
                        <div className="pt-4 px-2">
                            <p className="mb-2 mx-2 opacity-60 text-white text-xs">
                                The amount of SPND valid for upgrading is based on balances on the 11th October. Any static rewards earnt after this time is not valid for upgrading<br/><br/>
                                Otherwise please reach out on Telegram thanks.</p>
                        </div>
                        :
                        null
                }
                <Divider />
                <TokenArea>
                    <TokenSelect _token={newToken} title="Reward" readOnly="true" />
                    {
                        claimLive || userDeposited ?
                            userClaimed ?
                                <TokenAmount
                                    placeholder={formatValue(userUpgradeClaimAmount)}
                                    disabled={true}
                                    value=""
                                />
                                :
                                <TokenAmount
                                    placeholder=""
                                    disabled={true}
                                    value={ formatValue(entitlmentAmount) }
                                />
                            :
                            <TokenAmount
                                placeholder={ formatValue(entitlmentAmount) }
                                disabled={true}
                                value=""
                            />
                    }
                </TokenArea>
                <div className="p-3 flex items-center justify-between flex-col">
                    {
                        account && isRightNetwork(chainId) && allowance > 0 ?
                            !hasStaked ?
                                userClaimed ?
                                    <Button disabled={true}  className="w-full anji-colorful">Already Claimed</Button>
                                    :
                                    claimLive ?
                                        userDeposited ?
                                            <Button
                                                className="w-full anji-colorful"
                                                disabled={status === STATUS.PENDING || !inSnapshot}
                                                onClick={claimUpgrade}
                                            >
                                                Claim Upgrade
                                            </Button>
                                        :
                                            <Button
                                                className="w-full anji-colorful"
                                                disabled={status === STATUS.PENDING || !inSnapshot}
                                                onClick={depositOldToken}
                                            >
                                                Upgrade
                                            </Button>
                                    :
                                        userDeposited ?
                                            <div className="w-full">
                                                <p className="text-xs text-white opacity-60 mb-2">You can claim your anji upgrade once launched.</p>
                                                <Button
                                                    className="w-full anji-colorful"
                                                    disabled={true}
                                                >
                                                    Claim Upgrade
                                                </Button>
                                            </div>
                                        :
                                            <Button
                                                className="w-full anji-colorful"
                                                disabled={status === STATUS.PENDING || !inSnapshot || userDeposited}
                                                onClick={depositOldToken}
                                            >
                                                Deposit Upgrade
                                            </Button>
                            :
                            <div className="w-full">
                                <p className="text-xs text-white opacity-60 mb-2">The connected wallet still has SPND staked. Before claiming your upgrade, please unstake.</p>
                                <Button disabled={true}  className="w-full anji-colorful">SPND still staked</Button>
                            </div>
                        :
                        hasStaked ?
                            <div className="w-full">
                                <p className="text-xs text-white opacity-60 mb-2">The connected wallet still has SPND staked. Before claiming your upgrade, please unstake.</p>
                                <Button disabled={true}  className="w-full anji-colorful">SPND still staked</Button>
                            </div>
                            :
                            null
                    }
                </div>
            </WidgetContainer>
            {
                account && isRightNetwork(chainId) && allowance === 0 && inSnapshot && !hasStaked ?
                <div className="mt-5 text-white w-full">
                    <div style={{ fontSize: 11 }}>
                        Approve your wallet to allow AnjiSwap to upgrade your SPND.
                    </div>
                    <div className="anji-green btn rounded-3xl py-3 mt-3 cursor-pointer" onClick={status === STATUS.READY ? approveToken : null}>
                    Approve
                    {
                        status === STATUS.PENDING &&
                        <div
                            className="absolute w-full h-full left-0 top-0 rounded-full"
                            style={{
                                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
                            }}
                        />
                    }
                    </div>
                </div>
                :
                null
            }
        </>
    )
}
