const originalSetItem = localStorage.setItem
localStorage.setItem = function () {
    originalSetItem.apply(this, arguments)
    const event = new Event('storageChange')
    document.dispatchEvent(event)
}

const originalRemoveItem = localStorage.removeItem
localStorage.removeItem = function () {
    originalRemoveItem.apply(this, arguments)
    const event = new Event('storageChange')
    document.dispatchEvent(event)
}
