import { createStore, combineReducers } from 'redux'
// import { composeWithDevTools } from "redux-devtools-extension";

import application from './application/reducer'
import swap from './swap/reducer'
import tx from './tx/reducer'
import lists from './lists/reducer'
import user from './user/reducer'
import multicall from './multicall/reducer'


import { updateVersion } from './global/actions'

export default function configureStore(initialState) {
  const reducer = combineReducers({
    application,
    swap,
    tx,
    lists,
    user,
    multicall,
  })
  const store = createStore(reducer ,initialState +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  store.dispatch(updateVersion())
  return store
}